import * as React from "react";
import RichTextInput from 'ra-input-rich-text';
import {
    List,
    Datagrid,
    TextField,
    Edit,
    Create,
    SimpleForm,
    TextInput,
    NumberInput,
    RadioButtonGroupInput
} from 'react-admin';
import { DefaultEditCreateActions, DefaultListActions, DefaultPagination } from '../lib/defaultConfig'

const types = [
    { id: 1, name: 'Программа' },
    { id: 2, name: 'Курс' },
    { id: 3, name: 'Модуль' },
    { id: 4, name: 'Урок' },
    { id: 5, name: 'Факультатив' }
]

export const ItemList = props => (
    <List {...props} actions={<DefaultListActions />} pagination={<DefaultPagination />}>
        <Datagrid rowClick="edit">
            <TextField source="id" />
            <TextField source="title" />
        </Datagrid>
    </List>
);
const simpleForm = 
<SimpleForm>
    <TextInput source="title" fullWidth />
    <RichTextInput source="desc" />
    <RichTextInput source="content" />
    <RadioButtonGroupInput source="type" choices={types} />
    <TextInput source="image" fullWidth label="Image URL" />
    <TextInput source="tags" fullWidth helperText="Use a comma. Example: wealth, investment, finance" />
    <NumberInput source="totalPartners" />
    <NumberInput source="likes" />
    <TextInput source="newPrice" />
    <TextInput source="oldPrice" />
</SimpleForm>
export const ItemEdit = props => (
    <Edit {...props} actions={<DefaultEditCreateActions/>} mutationMode={'pessimistic'}>
        {simpleForm}
    </Edit>
);
export const ItemCreate = props => (
    <Create {...props} actions={<DefaultEditCreateActions/>}>
        {simpleForm}
    </Create>
);
