import * as React from "react";
import {
    List,
    Datagrid,
    TextField,
    Show,
    SimpleShowLayout,
    ReferenceField,
    FilterLiveSearch
} from 'react-admin';
import { DefaultShowActions, DefaultListActions, DefaultPagination } from '../lib/defaultConfig'
import { Card as MuiCard, CardContent, withStyles } from '@material-ui/core';
import { ManagerLogTypeFilter } from '../lib/ManagerLogFilter';

const Card = withStyles(theme => ({
    root: {
        [theme.breakpoints.up('sm')]: {
            order: 1, // display on the left rather than on the right of the list
            width: '15em',
            marginRight: '1em',
        },
        [theme.breakpoints.down('sm')]: {
            display: 'none',
        },
    },
}))(MuiCard);

const labels = {
    managerName: "Менеджер",
    route: "URL",
    type: 'Тип действия',
    data: 'Данные',
    createdAt: 'Дата',
}
const FilterSidebar = () => (
    <Card>
        <CardContent>
            <FilterLiveSearch source="route" label={labels.route} />
            <ManagerLogTypeFilter />
        </CardContent>
    </Card>
);

export const ManagerLogList = props => (
    <List {...props} actions={<DefaultListActions />} pagination={<DefaultPagination />} aside={<FilterSidebar />} >
        <Datagrid rowClick="show">
            <TextField source="id" />
            <ReferenceField link={false} label={labels.managerName} source="managerId" reference="manager">
                <TextField source="name" />
            </ReferenceField>
            <TextField source="route" label={labels.route} />
            <TextField source="type" label={labels.type} />
            <TextField source="createdAt" label={labels.createdAt} />
        </Datagrid>
    </List>
);

export const ManagerLogShow = props => (
    <Show {...props} actions={<DefaultShowActions />}>
        <SimpleShowLayout>
            <ReferenceField link={false} label={labels.managerName} source="managerId" reference="manager">
                <TextField source="name" />
            </ReferenceField>
            <TextField source="route" fullWidth label={labels.route} />
            <TextField source="type" fullWidth label={labels.type} />
            <TextField source="createdAt" label={labels.createdAt} />
            <TextField source="data" fullWidth label={labels.data} />
        </SimpleShowLayout>
    </Show>
);