import React, { useState, useEffect } from "react";
import { useHistory } from "react-router-dom";
import {
	Table, TableBody, TableCell, TableContainer, TableHead, TableRow,
	// TablePagination
} from '@material-ui/core'
import Cookies from 'universal-cookie'
import axios from 'axios'
import { getGraphColors } from '../lib/colors'
import { LineChart } from "../components/Charts";
import { Card, CardBody, CardHeader } from "../components/Card";
import { Row, Col } from "reactstrap";
const cookies = new Cookies();
const serverUrl = process.env.REACT_APP_APIURL || ''
const template = process.env.REACT_APP_TEMPLATE || 'academy'

const labels = {
	moduleName: "Наименование урока",
	join: "Открыли урок",
	complete: "Выполнили задание к уроку",
	estimate: 'Получили баллы за задания',
}

export const CourseStatisticsList = ({ props }) => {
	const [token] = useState(cookies.get('token'))
	const [statistics, setStatistics] = useState([])
	const [chartLabels, setChartLabels] = useState([])
	const [datasets, setDatasets] = useState([])
	const graphColors = getGraphColors(template)
	const history = useHistory()
	const pathArr = history.location.pathname.split('/')
	const courseId = pathArr[pathArr.length - 1]

	useEffect(() => {
		getData()
	}, [])
	const getData = () => {
		axios.get(`${serverUrl}/report_statistic/course/${courseId}`, {
			headers: { "A-Auth-Token": token },
		}).then((res) => {
			setStatistics(res.data)
			setDatasets(res.data.map(({ moduleName, join, complete, estimate }, index) => ({
				label: moduleName,
				data: [join, complete, estimate],
				backgroundColor: graphColors.transparentArray[index > graphColors.colorArray.length ? index - graphColors.colorArray.length : index],
				hoverBackgroundColor: graphColors.hoverArray[index > graphColors.colorArray.length ? index - graphColors.colorArray.length : index],
				borderColor: graphColors.colorArray[index > graphColors.colorArray.length ? index - graphColors.colorArray.length : index],
				borderWidth: 3,
			})
			))
			setChartLabels(['Открыли урок', 'Выполнили задание к уроку', 'Получили баллы за задания'])
		})
	}
	return (
		<>
			<Row className="w-100">
				<Col lg={12} md={12} className="py-2">
					<Card>
						<CardHeader>
							Статистика по курсу
						</CardHeader>
						<CardBody>
							<LineChart labels={chartLabels} datasets={datasets} stepSize={10} />
						</CardBody>
					</Card>
				</Col>
			</Row>
			<TableContainer sx={{ maxHeight: 440 }}>
				<Table stickyHeader aria-label="sticky table">
					<TableHead>
						<TableRow>
							<TableCell>{labels.moduleName}</TableCell>
							<TableCell align="right">{labels.join}</TableCell>
							<TableCell align="right">{labels.complete}</TableCell>
							<TableCell align="right">{labels.estimate}</TableCell>
						</TableRow>
					</TableHead>
					<TableBody>
						{
							statistics.map((statistic) => (
								<TableRow key={statistic.id} hover onClick={() => history.push(`/report_statistic/course/${courseId}/module/${statistic.id}`)}>
									<TableCell component="th" scope="row">{statistic.moduleName}</TableCell>
									<TableCell align="right">{statistic.join}</TableCell>
									<TableCell align="right">{statistic.complete}</TableCell>
									<TableCell align="right">{statistic.estimate}</TableCell>
								</TableRow>
							))
						}
					</TableBody>
				</Table>
				{/* <TablePagination
					rowsPerPageOptions={[50, 100, 250]}
					component="div"
					count={statistics.length}
					rowsPerPage={50}
					page={0}
				onPageChange={handleChangePage}
				onRowsPerPageChange={handleChangeRowsPerPage}
				/> */}
			</TableContainer>
		</>
	)
};