import * as React from "react";
import {
    List,
    Datagrid,
    TextField,
    Edit,
    Create,
    SimpleForm,
    SelectInput,
    AutocompleteInput,
    ReferenceInput,
    ReferenceArrayInput,
    AutocompleteArrayInput,
    FilterLiveSearch,
    useTranslate
} from 'react-admin';
import { DefaultEditCreateActions, DefaultListActions, DefaultPagination, DefaultListPerPage } from '../lib/defaultConfig'
import { DefaultFilterSidebar, UserIdFilter, CourseIdFilter } from "../components/ListFilters";

export const CourseUserManagerList = props => {
    const t = useTranslate();

    return (
        <List {...props} actions={<DefaultListActions />} 
            pagination={<DefaultPagination />}
            perPage={DefaultListPerPage}
            aside={<DefaultFilterSidebar>
                <FilterLiveSearch source="managerId" label={t('msg.managerId', { _: "Manager ID" })} />
                <UserIdFilter />
                <CourseIdFilter />
            </DefaultFilterSidebar>}
        >
            <Datagrid rowClick="edit">
                <TextField source="id" />
                <TextField source="managerName" />
                <TextField source="userFullName" />
                <TextField source="courseName" />
            </Datagrid>
        </List>
    )
}

const simpleForm =
    <SimpleForm>
        {/* <NumberInput source="userId" min="0" /> */}

        <ReferenceInput source="managerId" reference="manager" sort={{ field: 'name', order: 'ASC' }} perPage={1000} fullWidth>
            <SelectInput optionText="name"  optionValue="id"/>
        </ReferenceInput>
        <ReferenceInput source="courseId" reference="course" sort={{ field: 'title', order: 'ASC' }} perPage={1000} fullWidth>
            <SelectInput optionText="title" />
        </ReferenceInput>
        <ReferenceInput source="userId" reference="admin_panel/user_by_email">
            <AutocompleteInput optionText="email" />
        </ReferenceInput>
    </SimpleForm>
export const CourseUserManagerEdit = props => (
    <Edit {...props} actions={<DefaultEditCreateActions />} mutationMode={'pessimistic'}>
        {simpleForm}
    </Edit>
)
export const CourseUserManagerCreate = props => (
    <Create {...props} actions={<DefaultEditCreateActions />}>
        {simpleForm}
    </Create>
)