import * as React from "react";
import {
    List,
    Datagrid,
    TextField,
    Edit,
    Create,
    SimpleForm,
    TextInput,

} from 'react-admin';
import { DefaultEditCreateActions, DefaultListActions, DefaultPagination, DefaultListPerPage } from '../lib/defaultConfig';
import { DefaultFilterSidebar, IdFilter } from "../components/ListFilters";

export const FilmGenreList = props => (
    <List {...props} actions={<DefaultListActions />} pagination={<DefaultPagination />}
        perPage={DefaultListPerPage}
        aside={<DefaultFilterSidebar>
            <IdFilter />
        </DefaultFilterSidebar>}
    >
        <Datagrid rowClick="edit">
            <TextField source="id" />
            <TextField source="title" />
        </Datagrid>
    </List>
);
export const FilmGenreEdit = props => (
    <Edit {...props} actions={<DefaultEditCreateActions/>} mutationMode={'pessimistic'}>
        <SimpleForm>
            <TextInput source="title" fullWidth />
        </SimpleForm>
    </Edit>
);
export const FilmGenreCreate = props => (
    <Create {...props} actions={<DefaultEditCreateActions/>}>
        <SimpleForm>
            <TextInput source="title" fullWidth />
        </SimpleForm>
    </Create>
);