import React from 'react';
import { useInput } from 'ra-core';

import LessonTest from './LessonTest'


const TestInput = (props) => {
    const testData = [{
        id: '1',
        type: 'test',
        title: 'Тест 1',
        questions: []
    }]
    const {
        classes: classesOverride,
        configureQuill,
        helperText,
        label,
        source,
        refSource,
        resource,
        variant,
        ...rest
    } = props;

    const {
        input: { value, onChange }
    } = useInput({ source, ...rest });

    const valueType = useInput({ source: "type", ...rest });
    
    const changeData = (data) => {
        console.log('changeData', data)
        let findData = Object.values(components).find((obj, index) => obj.id === data.id)
        let findDataIndex = components.indexOf(findData)
        let newComponents = JSON.parse(JSON.stringify(components))
        newComponents[findDataIndex] = data
        console.log('newComponents',newComponents)
        setComponents([...newComponents])
        
        onChange(JSON.stringify(newComponents));
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
    let dataComponent
    if (value) {
        dataComponent = JSON.parse(value)
    }
    else
        dataComponent = []

    if (dataComponent.length === 0) {
        dataComponent = testData
    }
    const [components, setComponents] = React.useState(dataComponent)

    return (

        <div> {valueType.input.value === 'test' ? components.map((component) => {
            return <LessonTest data={component} changeData={changeData} />
        }) : ''}</div>
    );
};

export default TestInput;
