import * as React from "react";
import {
	TextField,
	FormGroup,
	Button,
	Checkbox,
	Accordion,
	AccordionSummary,
	Typography,
	AccordionDetails,
	FormControlLabel
} from '@material-ui/core'
import ExpandMoreIcon from '@material-ui/icons/ExpandMore'
import DeleteOutlinedIcon from '@material-ui/icons/DeleteOutlined';
import classes from './Components.module.css'

const labels = {
	'title': 'Название',
	'desc': 'Описание',
	'visible': 'Видимый',
	'grade': 'Необходима оценка',
}
const AccordionRow = ({ element, ...props }) => {
	return (
		<Accordion>
			<AccordionSummary expandIcon={<ExpandMoreIcon />}>
				<Typography>{element.title}</Typography>
			</AccordionSummary>
			<AccordionDetails className={classes.flex__column}>
				{props.fields.map((field, index) => {
					let type = 'string'
					//new format
					const params = field.split(':')
					if(params.length>0){
						field = params[0]
						type = params[1]
					}
					let result
					switch (type) {
						case 'bool':
							const checked = element[field] === '' ? false: element[field]
							result = <FormControlLabel
							control={<Checkbox
								checked={checked}
								className={classes.question_title__input}
								onChange={(event) => props.changeField(event, field, element.id)}
							/>}
							label={labels[field]}
						  />
							break;
					
						default:
							result = <TextField
										label={labels[field]}
										variant="filled"
										fullWidth
										placeholder={labels[field]}
										value={element[field]}
										className={classes.question_title__input}
										onChange={(event) => props.changeField(event, field, element.id)}
									/>
							break;
					}
					return <FormGroup row className={`${classes.flex__inputs} ${classes.align__center} ${classes.pt__40}`}>
								{result}
								{index === 0 && <Button variant="contained" className={classes.delete__btn} onClick={() => props.deleteRow(element)}>
									<DeleteOutlinedIcon style={{ color: 'white' }} />
								</Button>}
								
							</FormGroup>
				}
				)}

			</AccordionDetails>
		</Accordion>
	)
};

export default AccordionRow
