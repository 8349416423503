import * as React from "react";
import { Card, CardContent, withStyles } from '@material-ui/core';
import { Stack } from '@mui/material'

const FilterCard = withStyles(theme => ({
    root: {
        [theme.breakpoints.up('sm')]: {
            order: 1, // display on the left rather than on the right of the list
            width: '15em',
            marginRight: '1em',
        },
        [theme.breakpoints.down('sm')]: {
            display: 'none',
        },
    },
}))(Card);

export default (props) => {
    return (
        <FilterCard>
            <CardContent>
                <Stack>
                    {props.children}
                </Stack>
            </CardContent>
        </FilterCard>
    )
};