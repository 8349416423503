// in src/customRoutes.js
import * as React from "react"
import { Route } from "react-router-dom"
import CatalogUserImport from "../pages/catalogUserImport"
import MembershipUserImport from "../pages/membershipUserImport"
import CourseFileTask from "../pages/courseFileTask"
import UserProfile from "../pages/userProfile"
import DevelopDashboard from "../pages/developDashboard"
import UserStudyHistory from "../pages/userStudyHistory"
import { CourseStatisticsList } from "../pages/courseStatistics"
import { LessonStatisticsList } from "../pages/lessonStatistics"
const customRoutes = [
    <Route exact path="/catalog_user_import" component={CatalogUserImport} />,
    <Route
        exact
        path="/membership_user_import"
        component={MembershipUserImport}
    />,
    <Route exact path="/course_file_task" component={CourseFileTask} />,
    <Route exact path="/develop_dashboard" component={DevelopDashboard} />,
    <Route exact path="/userProfile/:id" component={UserProfile} />,
    <Route
        exact
        path="/report_statistic/course/:courseId"
        component={CourseStatisticsList}
    />,
    <Route
        exact
        path="/report_statistic/course/:courseId/module/:moduleId"
        component={LessonStatisticsList}
    />,
    <Route
        exact
        path="/user_study_history_page"
        component={UserStudyHistory}
    />,
]
export default customRoutes
