import React, { useState } from 'react';
import { useInput } from 'ra-core';

import {
  Checkbox,
  FormControlLabel,
  Table,
  TableBody,
  TableRow,
  Typography,
  TableContainer,
  Paper,
} from '@mui/material';

const RoleActionGroup = ({ source, label, ...props }) => {
  const {
    input: { value, onChange },
  } = useInput({ source, ...props });

  useInput({ source: 'data', ...props });

  const defaultActions = {
    rows: [
      {
        title: 'Table Users',
        actions: [
          { title: 'Create', value: true },
          { title: 'Read', value: true },
          { title: 'Update', value: true },
          { title: 'Delete', value: true },
        ],
      },
    ],
  };

  const validActions =
    value && JSON.parse(value) ? JSON.parse(value) : defaultActions;

  const [roleMatrix, setRoleMatrix] = useState(validActions);

  const handleChange = ({ row, action }) => {
    let newRoleMatrix;
    setRoleMatrix((prevState) => {
      newRoleMatrix = {
        rows: prevState.rows.map((prevRow) =>
          row.title === prevRow.title
            ? {
                title: prevRow.title,
                actions: row.actions.map((prevAction) =>
                  action.title === prevAction.title &&
                  action.value === prevAction.value
                    ? { title: prevAction.title, value: !prevAction.value }
                    : prevAction
                ),
              }
            : prevRow
        ),
      };
      return newRoleMatrix;
    });
    onChange(JSON.stringify(newRoleMatrix));
  };

  return (
    <>
      <Typography sx={{ mb: 1 }}>{label}</Typography>
      <TableContainer component={Paper} sx={{ p: 2 }}>
        <Table aria-label="a dense table">
          <TableBody>
            {roleMatrix.rows.map((row) => (
              <TableRow
                sx={{
                  '&:last-child': { borderBottom: 'none' },
                  borderBottom: '1px solid gray',
                }}
              >
                <Typography sx={{ mt: 1 }}>{row.title}</Typography>
                {row.actions.map((action) => (
                  <FormControlLabel
                    control={
                      <Checkbox
                        checked={action.value}
                        onChange={() => handleChange({ row, action })}
                      ></Checkbox>
                    }
                    label={action.title}
                  />
                ))}
              </TableRow>
            ))}
          </TableBody>
        </Table>
      </TableContainer>
    </>
  );
};

export default RoleActionGroup;
