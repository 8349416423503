/* eslint-disable import/no-anonymous-default-export */
import { stringify } from 'query-string';
import { fetchUtils } from 'ra-core';
import Cookies from 'universal-cookie'
import { showNotification } from 'react-admin';

const cookies = new Cookies();
const imageFields = ['image', 'imageWide']
const fileFields = ['file']
const imageServerUrl = process.env.REACT_APP_IMAGEURL || ''
// import { DataProvider } from 'ra-core';
const fetchJson = (url, options) => {

    return fetch(url, options)
        .then(response =>
            response.text().then(text => ({
                status: response.status,
                statusText: response.statusText,
                headers: response.headers,
                body: text,
            }))
        )
        .then(({ status, statusText, headers, body }) => {
            let json;
            try {
                json = JSON.parse(body);
            } catch (e) {
                // not json, no big deal
            }

            if (status < 200 || status >= 300) {
                return Promise.reject(
                    new Error(
                        json?.message || statusText,
                        status,
                        json
                    )
                );
            }
            return Promise.resolve({ status, headers, body, json });
        });
};
const getLocale = localStorage.getItem('localeContent')
const defaultHeaders = () => {
    return {
        'Accept-Language': getLocale,
        'Content-Type': 'application/json',
        'A-Auth-Token': cookies.get('token')
    }
}

const getFileInputObj = (fileName, isFileField) => {
    return {
        title: fileName,
        fileName: fileName,
        src: `${imageServerUrl ? imageServerUrl + '/' : ''}${isFileField ? 'files/' : ''}${fileName}`
    }
}

const modifyFileUrlsForList = (data, fieldList, isFileField) => {
    fieldList.forEach((fieldName) => {
        const files = data[fieldName]
        if (files) {
            if (Array.isArray(files)) {
                data[fieldName] = files.map(fileName => getFileInputObj(fileName, isFileField));
            } else {
                data[fieldName] = getFileInputObj(files, isFileField);
            }
        }
    });
}

const modifyFileUrls = (data) => {
    modifyFileUrlsForList(data, imageFields)
    modifyFileUrlsForList(data, fileFields, true)
}

export default (apiUrl, httpClient = fetchJson) => ({
    getList: (resource, params) => {
        const { page, perPage } = params.pagination;
        const { field, order } = params.sort;
        const query = {
            ...fetchUtils.flattenObject(params.filter),
            _sort: field,
            _order: order,
            _start: (page - 1) * perPage,
            _end: page * perPage,
        };
        const url = `${apiUrl}/${resource}?${stringify(query)}`;
        return httpClient(url, {
            headers: defaultHeaders()
        }).then(({ headers, json }) => {
            if (!headers.has('x-total-count')) {
                throw new Error(
                    'The X-Total-Count header is missing in the HTTP Response. The jsonServer Data Provider expects responses for lists of resources to contain this header with the total number of results to build the pagination. If you are using CORS, did you declare X-Total-Count in the Access-Control-Expose-Headers header?'
                );
            }

            json.forEach((item, i) => {
                modifyFileUrls(item)
            })

            return {
                data: json,
                total: parseInt(
                    headers.get('x-total-count').split('/').pop(),
                    10
                ),
            };
        });
    },

    getOne: (resource, params) =>
        httpClient(`${apiUrl}/${resource}/${params.id}`, {
            headers: defaultHeaders()
        }).then(({ json }) => {
            // find images and complete image urls
            modifyFileUrls(json)
            return {
                data: json,
            }
        }),

    getMany: (resource, params) => {
        const query = {
            id: params.ids,
        };
        const url = `${apiUrl}/${resource}?${stringify(query)}`;
        return httpClient(url, {
            headers: defaultHeaders()
        }).then(({ json }) => ({ data: json }));
    },

    getManyReference: (resource, params) => {
        const { page, perPage } = params.pagination;
        const { field, order } = params.sort;
        const query = {
            ...fetchUtils.flattenObject(params.filter),
            [params.target]: params.id,
            _sort: field,
            _order: order,
            _start: (page - 1) * perPage,
            _end: page * perPage,
        };
        const url = `${apiUrl}/${resource}?${stringify(query)}`;

        return httpClient(url).then(({ headers, json }) => {
            if (!headers.has('x-total-count')) {
                throw new Error(
                    'The X-Total-Count header is missing in the HTTP Response. The jsonServer Data Provider expects responses for lists of resources to contain this header with the total number of results to build the pagination. If you are using CORS, did you declare X-Total-Count in the Access-Control-Expose-Headers header?'
                );
            }
            return {
                data: json,
                total: parseInt(
                    headers.get('x-total-count').split('/').pop(),
                    10
                ),
            };
        });
    },

    update: (resource, params) =>
        httpClient(`${apiUrl}/${resource}/${params.id}`, {
            headers: defaultHeaders(),
            method: 'PUT',
            body: JSON.stringify(params.data),
        }).then(({ json }) => ({ data: json })),

    // json-server doesn't handle filters on UPDATE route, so we fallback to calling UPDATE n times instead
    updateMany: (resource, params) =>
        Promise.all(
            params.ids.map(id =>
                httpClient(`${apiUrl}/${resource}/${id}`, {
                    headers: defaultHeaders(),
                    method: 'PUT',
                    body: JSON.stringify(params.data),
                })
            )
        ).then(responses => ({ data: responses.map(({ json }) => json.id) })),

    create: (resource, params) =>
        httpClient(`${apiUrl}/${resource}`, {
            headers: defaultHeaders(),
            method: 'POST',
            body: JSON.stringify(params.data),
        }).then(({ json }) => ({
            data: { ...params.data, id: json.id },
        })),

    delete: (resource, params) =>
        httpClient(`${apiUrl}/${resource}/${params.id}`, {
            headers: defaultHeaders(),
            method: 'DELETE',
        }).then(({ json }) => ({ data: json })),

    // json-server doesn't handle filters on DELETE route, so we fallback to calling DELETE n times instead
    deleteMany: (resource, params) =>
        Promise.all(
            params.ids.map(id =>
                httpClient(`${apiUrl}/${resource}/${id}`, {
                    headers: defaultHeaders(),
                    method: 'DELETE',
                })
            )
        ).then(responses => ({ data: responses.map(({ json }) => json.id) })),
});
