import * as React from "react";
import {
    List,
    Datagrid,
    TextField,
    Show,
    SimpleShowLayout,
    DateField,
    FunctionField,
    useTranslate
} from 'react-admin';
import {
    DefaultShowActions,
    DefaultListActions,
    DefaultPagination,
    DefaultListPerPage
} from '../lib/defaultConfig'
import { DefaultFilterSidebar, UserIdFilter, CourseIdFilter } from "../components/ListFilters";

const fileServerUrl = process.env.REACT_APP_IMAGEURL || ''

export const UserCertificateList = props => {
    const t = useTranslate();
    return (
        <List {...props} actions={<DefaultListActions />} pagination={<DefaultPagination />}
            perPage={DefaultListPerPage}
            aside={<DefaultFilterSidebar>
                <UserIdFilter global />
                <CourseIdFilter />
            </DefaultFilterSidebar>}
        >
            <Datagrid rowClick="show">
                <TextField source="id" />
                <TextField source="number" fullWidth />
                <TextField source="userId" fullWidth />
                <TextField source="courseId" fullWidth />
                <TextField source="userName" fullWidth />
                <TextField source="courseName" fullWidth />
                <FunctionField
                    render={record =>
                        <a target="_blank" rel="noreferrer" href={`${fileServerUrl}/certificates/${record.file.fileName}`}>
                            {t('msg.download', { _: 'Download' })}
                        </a>}
                    label={t('resources.user_certificate.fields.file', { _: 'Certificate' })}/>
                <TextField source="medalType" />
                <TextField source="lang" />
                <DateField source="createdAt" />
            </Datagrid>
        </List>
    )
};

const simpleForm =
    <SimpleShowLayout>
        <TextField source="userId" fullWidth />
        <TextField source="courseId" fullWidth />
        <TextField source="userName" fullWidth />
        <TextField source="courseName" fullWidth />
        {/* <FunctionField render={record => <a target="_blank" rel="noreferrer" href={`${fileServerUrl}/certificates/${record.file}`}>{`${fileServerUrl}/certificates/${record.file}`}</a>} /> */}
        <TextField source="medalType" />
        <TextField source="lang" />
        <DateField source="createdAt" />
    </SimpleShowLayout>

export const UserCertificateShow = props => (
    <Show  {...props} actions={<DefaultShowActions />}>
        {simpleForm}
    </Show>
);
