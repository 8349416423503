import * as React from "react";
import CustomTextInput from './components/CustomTextInput';
import {
    List,
    Datagrid,
    TextField,
    Edit,
    Create,
    SimpleForm,
    TextInput,
    DateField,
    BooleanInput,
    DateTimeInput,
    RichTextField,
    ImageInput,
    ImageField,
    RadioButtonGroupInput,
    useTranslate
} from 'react-admin';
import { DefaultEditCreateActions, DefaultListActions, DefaultPagination, DefaultListPerPage } from '../lib/defaultConfig'

const types = [
    { id: 1, name: 'Корпоративный' },
    { id: 2, name: 'Региональный' },
    { id: 3, name: 'Местный' }
]

export const EventList = props => (
    <List {...props} actions={<DefaultListActions />} perPage={DefaultListPerPage} pagination={<DefaultPagination />}>
        <Datagrid rowClick="edit">
            <TextField source="id" />
            <TextField source="title" />
            <RichTextField source="desc" />
            <DateField source="start" showTime locales="ru-RU" />
            <DateField source="end" showTime locales="ru-RU" />
        </Datagrid>
    </List>
);
const CustomSimpleForm = props => {
    const t = useTranslate();

    return (<SimpleForm {...props}>
        <TextInput source="title" fullWidth />
        <BooleanInput source="visible" />
        <CustomTextInput source="desc" fullWidth label={t('resources.events.fields.desc', { _: 'Description' })} />
        <CustomTextInput source="content" fullWidth label={t('resources.events.fields.content', { _: 'Content' })} />
        <RadioButtonGroupInput source="type" choices={types} />
        <ImageInput source="image" label="Related pictures" accept="image/*">
            <ImageField source="src" title="title" />
        </ImageInput>
        <TextInput source="location" />
        <TextInput source="data" multiline resettable fullWidth />
        <DateTimeInput source="start" locales="ru-RU" />
        <DateTimeInput source="end" locales="ru-RU" />
        <BooleanInput source="allDay" />
    </SimpleForm>)
};

export const EventEdit = props => (
    <Edit {...props} actions={<DefaultEditCreateActions />} mutationMode={'pessimistic'}>
        {<CustomSimpleForm />}
    </Edit>
);

export const EventCreate = props => (
    <Create {...props} actions={<DefaultEditCreateActions />}>
        {<CustomSimpleForm />}
    </Create>
);