import * as React from "react";
import { FilterLiveSearch, useTranslate } from 'react-admin';

export default (props) => {
    const { global } = props;
    const t = useTranslate();
    const labelText = global ? t('components.list_filters.globalUserId', { _: 'Global user ID' }) :
        t('components.list_filters.userId', { _: 'Local user ID' });

    return (
        <FilterLiveSearch source="userId" label={labelText} />
    )
};