// in src/Foo.js
import React, { Component } from 'react';
import Card from '@material-ui/core/Card';
import CardContent from '@material-ui/core/CardContent';
import LoadingButton from '@mui/lab/LoadingButton'
import axios from 'axios';
import Dialog from '../components/Dialog'
import Table from '../components/Table'
const serverUrl = process.env.REACT_APP_APIURL || ''
class App extends Component {

  constructor(props) {
    super(props)
    this.state = {
      selectedFile: null,
      message: null,
      dialog: false,
      uploading: false,
    }
  }

  handleOpen = () => {
    this.setState({ dialog: true })
  }

  handleClose = () => {
    this.setState({ dialog: false })
  }

  onFileChange = event => {
    this.setState({ selectedFile: event.target.files[0] });
  };

  emptyFileUpload = () => {
    this.setState({ message: 'Unable to upload empty file' })
    this.handleOpen()
    this.setState({ uploading: false })
  }

  onFileUpload = () => {
    this.setState({ uploading: true })
    const formData = new FormData();
    try {
      formData.append(
        "file",
        this.state.selectedFile,
        this.state.selectedFile.name
      );
    } catch (e) {
      return this.emptyFileUpload()
    }

    axios.post(`${serverUrl}/admin_panel/import_catalog_user`, formData).then(({ data }) => {
      const resultData = data.data.map((v, i) => { return { '#': i+1, ...v } })
      this.setState({ message: <Table data={resultData} /> })
      if (data.message) this.handleOpen()
      this.setState({ uploading: false })
    }).catch(({ data }) => {
      const resultData = data.data.map((v, i) => { return { '#': i+1, ...v } })
      this.setState({ message: <Table data={resultData} /> })
      if (data.message) this.handleOpen()
      this.setState({ uploading: false })
    });
  }

  fileData = () => {
    if (this.state.selectedFile) {
      return (
        <div>
          <h2>Информация о файле:</h2>
          <p>Имя файла: {this.state.selectedFile.name}</p>
          <p>Тип файла: {this.state.selectedFile.type}</p>
          <p>
            Последнее изменение:{" "}
            {new Date(this.state.selectedFile.lastModified).toLocaleString()}
          </p>
        </div>
      );
    } else {
      return (
        <div>
          <br />
        </div>
      );
    }
  };

  render() {
    return (
      <>
        <Dialog
          message={this.state.message}
          handleOpen={this.handleOpen}
          handleClose={this.handleClose}
          open={this.state.dialog}
          isComponent={true}
        />
        <Card>
          <h1>
            Импорт информации о покупках
          </h1>
          <CardContent>
            <input disabled={this.state.uploading} type="file" onChange={this.onFileChange} />
            {
              this.state.uploading ?
                <LoadingButton loading loadingIndicator="Loading..." variant="outlined">
                  Fetch data
                </LoadingButton>
                :
                <button onClick={this.onFileUpload}>
                  Загрузить
                </button>
            }
          </CardContent>
          {this.fileData()}
        </Card>
      </>
    );
  }
}

export default App; 