import * as React from "react";
import CustomTextInput from './components/CustomTextInput';
import {
	List,
	Datagrid,
	TextField,
	Edit,
	Create,
	SimpleForm,
	ImageInput,
	ImageField,
	TextInput
} from 'react-admin';
import { DefaultEditCreateActions, DefaultListActions, DefaultPagination } from '../lib/defaultConfig'

export const TestList = props => (
	<List {...props} actions={<DefaultListActions />} pagination={<DefaultPagination />}>
		<Datagrid rowClick="edit">
			<TextField source="id" />
			<TextField source="image" />
		</Datagrid>
	</List>
);
export const TestEdit = props => (
	<Edit {...props} actions={<DefaultEditCreateActions />} mutationMode={'pessimistic'}>
		<SimpleForm>
			<ImageInput source="image" label="Related pictures" accept="image/*">
				<ImageField source="src" title="title" />
			</ImageInput>
			<CustomTextInput source="content" fullWidth label={'Контент'} />
			<TextInput source="content" fullWidth />

		</SimpleForm>
	</Edit>
);
export const TestCreate = props => (
	<Create {...props} actions={<DefaultEditCreateActions />}>
		<SimpleForm>
			<ImageInput source="image" label="Related pictures" accept="image/*">
				<ImageField source="src" title="title" />
			</ImageInput>
		</SimpleForm>
	</Create>
);