import * as React from "react";
import CatalogTypeSwitcher from './components/CatalogTypeSwitcher';
import CatalogTypeField from './components/CatalogTypeField';
// import AccordionInput from './components/AccordionInput';
import {
    List,
    Datagrid,
    TextField,
    Edit,
    Create,
    SimpleForm,
    TextInput,
    NumberInput,
    RadioButtonGroupInput,
    BooleanInput,
    SelectField,
    ReferenceArrayInput,
AutocompleteArrayInput
} from 'react-admin';
import { DefaultEditCreateActions, DefaultListActions, DefaultPagination, DefaultListPerPage } from '../lib/defaultConfig';
import { IdFilter, DefaultFilterSidebar } from "../components/ListFilters";

const types = [
    { id: 1, name: 'Программа' },
    { id: 2, name: 'Курс' },
    { id: 3, name: 'Экзамен' }
]
const validate = (values) => {
    const errors = {};
    console.log('validate', values)
    console.log('validate', values.newPrice)
    if (values.newPrice === '' || isNaN(values.newPrice) ) {
        errors.newPrice = 'Цена обязательна';
    }
    console.log(errors)
    return errors
};

export const CatalogList = props => (
    <List {...props} actions={<DefaultListActions />} perPage={DefaultListPerPage} pagination={<DefaultPagination />}
            aside={<DefaultFilterSidebar><IdFilter /></DefaultFilterSidebar>}>
        <Datagrid rowClick="edit">
            <TextField source="id" />
            <SelectField source="type" choices={types} />
            <CatalogTypeField />
        </Datagrid>
    </List>
);
const simpleForm = 
<SimpleForm validate={validate}>
    <BooleanInput source="visible" fullWidth initialValue={true}/>
    <BooleanInput source="sale" fullWidth />            
    <NumberInput source="position" min="0" />
    <RadioButtonGroupInput source="type" choices={types} />
    <CatalogTypeSwitcher/>
    <TextInput source="tags" fullWidth helperText="Use a comma. Example: wealth, investment, finance" />
    <NumberInput source="totalPartners" />
    <NumberInput source="likes" />
    <BooleanInput source="ableToSale" initialValue={true}/>
    <ReferenceArrayInput source="discountIds" reference="discount" sort={{ field: 'title', order: 'ASC' }} perPage={1000} fullWidth>
        <AutocompleteArrayInput optionText="title" optionValue="id" />
    </ReferenceArrayInput>
    <TextInput source="newPrice" />
    <TextInput source="oldPrice" />
    <TextInput source="newPriceUsd" />
    <TextInput source="oldPriceUsd" />
    <TextInput source="newPriceEur" />
    <TextInput source="oldPriceEur" />
    <NumberInput source="globalGoodId"/>
    <TextInput source="buyUrl" fullWidth />
    <ReferenceArrayInput source="visibleInLang" reference="langs" sort={{ field: 'title', order: 'ASC' }} perPage={1000} fullWidth>
        <AutocompleteArrayInput optionText="title" optionValue="id" />
    </ReferenceArrayInput>
    <ReferenceArrayInput source="visibleInGrade" reference="grade" sort={{ field: 'name', order: 'ASC' }} perPage={1000} fullWidth>
        <AutocompleteArrayInput optionText="name" optionValue="id" />
    </ReferenceArrayInput>
    <BooleanInput source="visibleInGradeZero" fullWidth initialValue={false}/>
    {/* // TODO */}
    {/* <ReferenceArrayInput source="giftIds" reference="catalog" sort={{ field: 'id', order: 'ASC' }} perPage={1000} fullWidth>
        <AutocompleteArrayInput optionValue="id" />
    </ReferenceArrayInput>  */}
    <TextInput source="giftIds" />
    <TextInput source="giftAccessDuration" />
    <NumberInput source="accessDuration" />
</SimpleForm>
export const CatalogEdit = props => (
    <Edit {...props} actions={<DefaultEditCreateActions />} mutationMode={'pessimistic'}>
        {simpleForm}
    </Edit>
);
export const CatalogCreate = props => (
    <Create {...props} actions={<DefaultEditCreateActions />}>
        {simpleForm}
    </Create>
);
