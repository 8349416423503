import polyglotI18nProvider from 'ra-i18n-polyglot'

import ru from '../assets/locales/ru/translation'
import en from '../assets/locales/en/translation'
import es from '../assets/locales/es/translation'
import hi from '../assets/locales/hi/translation'
import vi from '../assets/locales/vi/translation'
import it from '../assets/locales/it/translation'

const langs = [
    {
        key: 'en',
        title: 'English'
    },
    {
        key: 'ru',
        title: 'Русский'
    },
    {
        key: 'es',
        title: 'Español'
    },
    {
        key: 'hi',
        title: 'हिन्दी'
    },
    {
        key: 'vi',
        title: 'Việt nam'
    },
    {
        key: 'it',
        title: 'Italiano'
    }
]
function getLang() {
    const localStorageLang = localStorage.getItem('locale')
    if (localStorageLang) return localStorageLang
    if (navigator.languages !== undefined)
        return navigator.languages[0].split('-')[0];
    return navigator.language.split('-')[0];
}
function setLang(lang) {
    localStorage.setItem('locale', lang)
}
function combineLangObjects(lang) {
    let messages
    switch (lang) {
        case 'en':
            messages = en
            break;
        case 'es':
            messages = es
            break;
        case 'hi':
            messages = hi
            break;
        case 'vi':
            messages = vi
            break;
        case 'it':
            messages = it
            break;
        default:
            messages = ru
            break;
    }
    return messages
}
function geti18nProvider() {
    return polyglotI18nProvider(() => {

        return combineLangObjects(getLang())

    }, 'en');
    // return combineLangObjects(getLang())
}
export { langs, getLang, setLang, geti18nProvider }