import * as React from 'react';
import PropTypes from 'prop-types';
import { styled } from '@mui/material/styles';
import Box from '@mui/material/Box';
import TreeView from '@mui/lab/TreeView';
import TreeItem, { treeItemClasses } from '@mui/lab/TreeItem';
import Typography from '@mui/material/Typography';
import ArrowDropDownIcon from '@mui/icons-material/ArrowDropDown';
import ArrowRightIcon from '@mui/icons-material/ArrowRight';
import axios from 'axios'
import { FormControl, InputLabel } from '@material-ui/core';
import { FieldTitle } from 'ra-core';
import Cookies from 'universal-cookie'
import { useState } from 'react';
import { useEffect } from 'react';
import { useHistory } from "react-router-dom";
import { useTranslate } from 'react-admin';

const cookies = new Cookies();
const serverUrl = process.env.REACT_APP_APIURL || ''

const StyledTreeItemRoot = styled(TreeItem)(({ theme }) => ({
	color: theme.palette.text.secondary,
	[`& .${treeItemClasses.content}`]: {
		color: theme.palette.text.secondary,
		borderTopRightRadius: theme.spacing(2),
		borderBottomRightRadius: theme.spacing(2),
		paddingRight: theme.spacing(1),
		fontWeight: theme.typography.fontWeightMedium,
		'&.Mui-expanded': {
			fontWeight: theme.typography.fontWeightRegular,
		},
		'&:hover': {
			backgroundColor: theme.palette.action.hover,
		},
		'&.Mui-focused, &.Mui-selected, &.Mui-selected.Mui-focused': {
			backgroundColor: `var(--tree-view-bg-color, ${theme.palette.action.selected})`,
			color: 'var(--tree-view-color)',
		},
		[`& .${treeItemClasses.label}`]: {
			fontWeight: 'inherit',
			color: 'inherit',
		},
	},
	[`& .${treeItemClasses.group}`]: {
		marginLeft: 0,
		[`& .${treeItemClasses.content}`]: {
			paddingLeft: theme.spacing(2),
		},
	},
}));

function StyledTreeItem(props) {
	const {
		bgColor,
		color,
		labelIcon: LabelIcon,
		labelInfo,
		labelText,
		...other
	} = props;

	return (
		<StyledTreeItemRoot
			label={
				<Box sx={{ display: 'flex', alignItems: 'center', p: 0.5, pr: 0 }}>
					<Box component={LabelIcon} color="inherit" sx={{ mr: 1 }} />
					<Typography variant="body2" sx={{ fontWeight: 'inherit', flexGrow: 1 }}>
						{labelText}
					</Typography>
					<Typography variant="caption" color="inherit">
						{labelInfo}
					</Typography>
				</Box>
			}
			style={{
				'--tree-view-color': color,
				'--tree-view-bg-color': bgColor,
			}}
			{...other}
		/>
	);
}

StyledTreeItem.propTypes = {
	bgColor: PropTypes.string,
	color: PropTypes.string,
	labelIcon: PropTypes.elementType.isRequired,
	labelInfo: PropTypes.string,
	labelText: PropTypes.string.isRequired,
};
export default function GmailTreeView(props) {

	const [themes, setThemes] = useState([])
	const history = useHistory()
	const t = useTranslate();

	useEffect(() => {
		axios.get(`${serverUrl}/admin_panel/course_tree?courseId=${props.record.id}`, {
			headers: { "A-Auth-Token": cookies.get('token') },
		}).then(({ data }) => setThemes(data))
	}, [])

	return (
		<FormControl
			className="ra-rich-text-input"
		>
			<InputLabel shrink>
				<FieldTitle label={t('resources.course.fields.lessonTree', { _: 'Course content' })} />
			</InputLabel>
			<TreeView
				aria-label="themes"
				defaultCollapseIcon={<ArrowDropDownIcon />}
				defaultExpandIcon={<ArrowRightIcon />}
				defaultEndIcon={<div style={{ width: 24 }} />}
				sx={{ maxWidth: '100%', marginTop: '20px' }}
			>
				{
					themes.map((theme) => (
						<StyledTreeItem key={theme.id} nodeId={theme.id} labelText={theme.name}>
							{
								theme.modules.map((mod) => (
									<StyledTreeItem
										onClick={() => history.push(`/course_theme_module/${mod.id.replace('_', '')}`)}
										key={mod.id}
										nodeId={mod.id}
										labelText={mod.name}
									/>
								))
							}
						</StyledTreeItem>
					))
				}
			</TreeView>
		</FormControl>
	);
}
