import * as React from "react";
import CustomTextInput from './components/CustomTextInput';
import AccordionInput from './components/AccordionInput';
import TestInput from './components/TestInput';
import {
    List,
    Datagrid,
    TextField,
    Edit,
    Create,
    SimpleForm,
    TextInput,
    RadioButtonGroupInput,
    NumberInput,
    BooleanInput,
    FileInput,
    FileField,
    useTranslate
} from 'react-admin';
import { DefaultEditCreateActions, DefaultListActions, DefaultPagination, DefaultListPerPage } from '../lib/defaultConfig';
import { DefaultFilterSidebar, IdFilter } from "../components/ListFilters";

const types = [
    { id: 'lesson', name: 'Урок' },
    { id: 'test', name: 'Тест' }
]

export const CourseThemeModuleList = props => (
    <List {...props} actions={<DefaultListActions />} perPage={DefaultListPerPage} pagination={<DefaultPagination />}
            aside={<DefaultFilterSidebar><IdFilter /></DefaultFilterSidebar>}>
        <Datagrid rowClick="edit">
            <TextField source="id" />
            <TextField source="title" />
        </Datagrid>
    </List>
);

const CustomSimpleForm = props => {
    const t = useTranslate();

    return (<SimpleForm {...props}>
        <TextInput source="title" fullWidth />
        <CustomTextInput source="content" label={t('resources.course_theme_module.fields.content', { _: 'Content' })} />
        <NumberInput source="position" min="0" />
        <RadioButtonGroupInput source="type" choices={types} />
        <BooleanInput source="necessary" fullWidth />
        <BooleanInput source="taskFile" fullWidth />
        <BooleanInput source="calculated" fullWidth />
        <TextInput source="calculatedWeight" />
        <FileInput source="file" accept=".pdf,.jpg,.jpeg,.png" multiple={true} fullWidth >
            <FileField source="src" title="title" />
        </FileInput>
        <AccordionInput source="taskFiles" fullWidth fields={['title', 'grade:bool']} />
        <CustomTextInput source="taskFileDesc" label={t('resources.course_theme_module.fields.taskFileDesc', { _: 'Task text' })} />
        <NumberInput source="passingPercentage" min="0" max="100" />
        <TestInput source="data" />
        <TextInput source="video" fullWidth />
    </SimpleForm>)
};

export const CourseThemeModuleEdit = props => (
    <Edit {...props} actions={<DefaultEditCreateActions />} mutationMode={'pessimistic'}>
        {<CustomSimpleForm />}
    </Edit>
)
export const CourseThemeModuleCreate = props => (
    <Create {...props} actions={<DefaultEditCreateActions />}>
        {<CustomSimpleForm />}
    </Create>
)