import * as React from "react";
import {
    List,
    Datagrid,
    TextField,
    Edit,
    Create,
    SimpleForm,
    TextInput,
    ReferenceInput,
    ReferenceField,
    SelectInput,
    BooleanField,
    BooleanInput
} from 'react-admin';
import { DefaultEditCreateActions, DefaultListActions, DefaultPagination } from '../lib/defaultConfig'

export const CourseCompleteList = props => (
    <List {...props} actions={<DefaultListActions />} pagination={<DefaultPagination />}>
        <Datagrid rowClick="edit">
            <TextField source="id" />
            <ReferenceField label="Module Id" source="moduleId" reference="course_theme_module">
                <TextField source="title" />
            </ReferenceField>
            <TextField source="userId" />
            <BooleanField source="complete" />
        </Datagrid>
    </List>
);
const simpleForm = 
<SimpleForm>
    <TextInput source="userId" />
    <ReferenceInput label="Module Id" source="moduleId" reference="course_theme_module">
        <SelectInput optionText="title" />
    </ReferenceInput>

    <TextInput source="data" multiline resettable fullWidth />
    <BooleanInput source="complete" />
</SimpleForm>
export const CourseCompleteEdit = props => (
    <Edit {...props} actions={<DefaultEditCreateActions />} mutationMode={'pessimistic'}>
        {simpleForm}
    </Edit>
);
export const CourseCompleteCreate = props => (
    <Create {...props} actions={<DefaultEditCreateActions />}>
        {simpleForm}
    </Create>
);
