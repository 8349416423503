import React from 'react';
import {
	Chart as ChartJS,
	Tooltip,
	Legend,
	CategoryScale,
	LinearScale,
	BarElement,
} from 'chart.js';
import { Bar } from 'react-chartjs-2';
import { getGraphColors } from '../../lib/colors';
import classes from './Chart.module.css'

const BarChart = ({ template, ...props }) => {
	const graphColors = getGraphColors(template)

	ChartJS.register(CategoryScale, LinearScale, BarElement, Tooltip, Legend);
	const options = {
		onHover: (event, chartElement) => {
			const target = event.native ? event.native.target : event.target;
			target.style.cursor = chartElement[0] ? 'pointer' : 'default';
		},
		responsive: true,
		plugins: {
			legend: {
				position: 'none',
			},
			title: {
				display: false,
			},
			tooltip: {
				displayColors: false
			}
		},
		scales: {
			y: {
				beginAtZero: true
			}
		}
	};
	const labels = props.labels || [];
	const data = {
		labels,
		datasets: props.datasets || [
			{
				label: 'Да',
				data: props.data.yes || [],
				backgroundColor: graphColors.primary,
				hoverBackgroundColor: graphColors.primaryHover,
				barPercentage: 0.9,
			},
			{
				label: 'Нет',
				data: props.data.no || [],
				backgroundColor: graphColors.secondary,
				hoverBackgroundColor: graphColors.secondaryHover,
				barPercentage: 0.9,
			},
		],
	};
	return <Bar className={classes.canvas} options={options} data={data} />;
}

export default BarChart