import * as React from "react";
import CustomTextInput from './components/CustomTextInput';
import AccordionInput from './components/AccordionInput';
import {
    List,
    Datagrid,
    TextField,
    Edit,
    Create,
    SimpleForm,
    TextInput,
    ReferenceInput,
    SelectInput,
    ImageInput,
    ImageField,
    BooleanInput,
    ReferenceArrayInput,
    AutocompleteArrayInput,
    useTranslate
} from 'react-admin';
import { DefaultEditCreateActions, DefaultListActions, DefaultPagination, DefaultListPerPage } from '../lib/defaultConfig';
import { DefaultFilterSidebar, IdFilter } from "../components/ListFilters";

export const ProgramList = props => (
    <List {...props} actions={<DefaultListActions />} perPage={DefaultListPerPage} pagination={<DefaultPagination />}
            aside={<DefaultFilterSidebar><IdFilter /></DefaultFilterSidebar>}>
        <Datagrid rowClick="edit">
            <TextField source="id" />
            <TextField source="title" />
        </Datagrid>
    </List>
);

const CustomSimpleForm = props => {
    const t = useTranslate();

    return (<SimpleForm {...props}>
        <TextInput source="title" fullWidth />
        <TextInput source="titleShort" fullWidth />
        <BooleanInput source="visibleStudy" fullWidth initialValue={true} />
        <AccordionInput source="process" fullWidth fields={['title', 'desc']} />
        <AccordionInput source="result" fullWidth fields={['title']} />
        <CustomTextInput source="desc" label={t('resources.program.fields.desc', { _: 'Description' })} />
        <CustomTextInput source="content" label={t('resources.program.fields.content', { _: 'Content' })} />
        {/* <NumberInput source="position" min="0" /> */}
        <ImageInput source="image" label="Related pictures" accept="image/*">
            <ImageField source="src" title="title" />
        </ImageInput>
        <TextInput source="tags" fullWidth helperText="Use a comma. Example: wealth, investment, finance" />
        <ReferenceInput source="teacherId" reference="teacher">
            <SelectInput optionText="title" />
        </ReferenceInput>
        <ReferenceArrayInput reference="course" source="courses" sort={{ field: 'title', order: 'ASC' }} perPage={1000} fullWidth>
            <AutocompleteArrayInput optionText="title" optionValue="id" />
        </ReferenceArrayInput>
        <BooleanInput source="consistent" fullWidth />
    </SimpleForm>)
};

export const ProgramEdit = props => (
    <Edit {...props} actions={<DefaultEditCreateActions />} mutationMode={'pessimistic'}>
        {<CustomSimpleForm />}
    </Edit>
);
export const ProgramCreate = props => (
    <Create {...props} actions={<DefaultEditCreateActions />}>
        {<CustomSimpleForm />}
    </Create>
);
