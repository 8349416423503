import React from "react";
import { styled } from "@mui/system";

const StyledCard = styled('div')({
	background: '#fff',
	minHeight: '250px',
	boxShadow: '4px 4px 20px rgba(0, 0, 0, .2)',
	position: 'relative',
	marginBottom: '30px',
	borderRadius: '4px',
})

const StyledCardHeader = styled('div')({
	padding: '20px',
	position: 'relative',
	borderBottom: '1px solid rgba(204, 204, 204, 0.35)',
	margin: '0',
	fontSize: '18px',
	fontWeight: 'normal',
	color: '#111',
})

const StyledCardBody = styled('div')({
	fontSize: '14px',
	color: '#555',
	padding: '20px',
})

export const Card = ({ children }) => (
	<StyledCard>{children}</StyledCard>
)
export const CardHeader = ({ children }) => (
	<StyledCardHeader>{children}</StyledCardHeader>
)
export const CardBody = ({ children }) => (
	<StyledCardBody>{children}</StyledCardBody>
)