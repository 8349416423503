import * as React from "react";
import {
    List,
    Datagrid,
    TextField,
	SimpleForm,
	TextInput,
	BooleanInput,
	Edit,
	Create,
    useTranslate,
    BooleanField
} from 'react-admin';
import { DefaultEditCreateActions, DefaultListActions, DefaultPagination, DefaultListPerPage } from '../lib/defaultConfig'
import CustomTextInput from "./components/CustomTextInput";

export const SystemNotificationsList = props => (
    <List {...props} actions={<DefaultListActions />} perPage={DefaultListPerPage} pagination={<DefaultPagination />}>
        <Datagrid rowClick="edit">
            <TextField source="id" />
			<TextField source="title" />
            <BooleanField source="visible" />
        </Datagrid>
    </List>
);

const CustomSimpleForm = props => {
    const t = useTranslate();

    return (<SimpleForm {...props} redirect="list">
            <BooleanInput source="visible" fullWidth />
            <TextInput source="title" fullWidth />
            <CustomTextInput source="content" label={t('resources.system_notifications.fields.content', { _: 'Content' })} />
            <TextInput source="data" fullWidth />
            <CustomTextInput source="desc" label={t('resources.system_notifications.fields.desc', { _: 'Description' })} />
            <TextInput multiline fullWidth source="conditions" />
        </SimpleForm>)
};

export const SystemNotificationsEdit = props => (
    <Edit actions={<DefaultEditCreateActions />} {...props} mutationMode={'pessimistic'}>
        {<CustomSimpleForm />}
    </Edit>
);
export const SystemNotificationsCreate = props => (
    <Create actions={<DefaultEditCreateActions />} {...props}>
        {<CustomSimpleForm />}
    </Create>
);