// in src/Foo.js
import React, { Component } from 'react'
import Card from '@material-ui/core/Card'
import axios from 'axios'
import styled from 'styled-components'
import Cookies from 'universal-cookie'
import { isEmpty } from '../lib/lib';

const cookies = new Cookies();

const serverUrl = process.env.REACT_APP_APIURL || ''

const StyledCard = styled(Card)`
	padding: 16px;
`

const Wrapper = styled.div`
	display: flex;
	flex-direction: column;
`

const UserDataRow = styled.div`
	margin-top: 8px !important;
    margin-bottom: 4px !important;
	border: 0;
    margin: 0;
    display: inline-flex;
    padding: 0;
    position: relative;
    min-width: 0;
    flex-direction: column;
    vertical-align: top;
`

const KeyName = styled.div`
    color: rgba(0, 0, 0, 0.54);
    padding: 0;
    font-size: 1rem;
    font-family: "Roboto", "Helvetica", "Arial", sans-serif;
    font-weight: 400;
    line-height: 1;
    letter-spacing: 0.00938em;
	transform: translate(0, 1.5px) scale(0.75);
    transform-origin: top left;
`

const KeyData = styled.div`
    color: currentColor;
    width: 100%;
    border: 0;
    margin: 0;
    display: block;
    padding: 8px 0 4px;
    background: none;
    box-sizing: content-box;
    font-family: "Roboto", "Helvetica", "Arial", sans-serif;
    vertical-align: middle;
	font-size: 0.875rem;
    font-weight: 400;
    line-height: 1.43;
    letter-spacing: 0.01071em;
`

class UserProfile extends Component {
	constructor(props) {
		super(props)
		this.state = {
			userId: this.props.match.params.id,
			token: cookies.get('token'),
			userProfile: {}
		}
	}


	onFileChange = event => {
		this.setState({ selectedFile: event.target.files[0] });
	};
	componentDidMount = () => {
		console.log(this.state.token)
		this.getData()
	}
	getData = () => {
		axios.get(`${serverUrl}/userProfile/${this.state.userId}`, {
			headers: { "A-Auth-Token": this.state.token },
		}).then((res) => {
			this.setState({ userProfile: res.data })
		})
	}

	render() {
		return (
			<StyledCard>
				<Wrapper>
					{
						Object.keys(this.state.userProfile).map((key, index) => {
							if (key && !isEmpty(this.state.userProfile[key]))
								return (
									<UserDataRow key={index}>
										<KeyName>{key}</KeyName>
										<KeyData>{this.state.userProfile[key]}</KeyData>
									</UserDataRow>
								)
							else return <></>
						})
					}
				</Wrapper>
			</StyledCard>
		);
	}
}

export default UserProfile; 