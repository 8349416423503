import React from 'react';
import Button from '@mui/material/Button';
import { useHistory  } from "react-router-dom";

export default function ImportButton({resource, ...props}) {

	const history = useHistory (); 
	const routeChange = (resource) =>{ 
		const path = `${resource}_import`; 
		history.push(path);
	}

	return (
		<Button onClick={() => routeChange(resource)} {...props}>Import</Button>
	);
}