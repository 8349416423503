import * as React from 'react';
import { useSetLocale, TopToolbar, CreateButton, ExportButton, FilterButton, Button, ListButton, Pagination, useTranslate, FilterLiveSearch } from 'react-admin';
import classes from '../Model/components/Components.module.css'

import ChevronLeft from '@material-ui/icons/ChevronLeft';
import { useHistory } from "react-router-dom";
import { isEmpty } from './lib';
import ImportButton from '../components/ImportButton';

export const DefaultPaginationRowsPerPage = [50, 100, 250];
export const DefaultListPerPage = DefaultPaginationRowsPerPage[0];

export const DefaultPagination = props => <Pagination rowsPerPageOptions={DefaultPaginationRowsPerPage} {...props} />;

export const DefaultEditCreateActions = ({ basePath, data, resource }) => {
	let history = useHistory();
	const t = useTranslate()
	return <TopToolbar>
		<ListButton basePath={basePath} label={t('ra.action.back')} icon={<ChevronLeft />} onClick={() => { history.goBack() }} />
	</TopToolbar>
}

export const DefaultShowActions = ({ basePath, data, resource }) => {
	let history = useHistory()
	const t = useTranslate()
	return <TopToolbar>
		<ListButton basePath={basePath} label={t('ra.action.back')} icon={<ChevronLeft />} onClick={() => { history.goBack() }} />
	</TopToolbar>
}


export const DefaultListActions = (props) => {
	const langs = ["ru", "en", "es", "kk", "zh", "de", "it", "hi", "vi", "mn"]
	const defaultLang = 'ru'
	const createButton = props.create !== undefined ? props.create : true
	const filterButton = props.filter !== undefined ? props.filter : false
	const exportButton = props.export !== undefined ? props.export : true
	const importButton = props.import !== undefined ? props.import : false
	const resource = props.resource !== undefined ? props.resource : ''
	
	if(localStorage.getItem('localeContent') === null)
		localStorage.setItem('localeContent', defaultLang)
	
	const changeLang = (lang) => {
		localStorage.setItem('localeContent', lang)
		window.location.reload()
	};
	return (
		<TopToolbar className={classes.display__block}>
			<div className={classes.flex__between}>
				<div>
					{
						langs.map((lang, index) => (
							<Button
								key={index}
								className={`${classes.label_no_padding} ${localStorage.getItem('localeContent') === lang ? classes.active__btn : ''}`}
								onClick={() => changeLang(lang)}
								label={lang}
							>
							</Button>
						))
					}
				</div>
				<div>
					{importButton && resource ? <ImportButton resource={resource} /> : ''}
					{filterButton ? <FilterButton /> : ''}
					{createButton ? <CreateButton /> : ''}
					{exportButton ? <ExportButton maxResults={props.total} /> : ''}
				</div>
			</div>
		</TopToolbar>
	);
};
