import * as React from "react";
import CustomTextInput from './components/CustomTextInput';
import {
    List,
    Datagrid,
    TextField,
    Edit,
    Create,
    SimpleForm,
    TextInput,
    ImageInput,
    ImageField,
    BooleanInput,
    useTranslate
} from 'react-admin';
import { DefaultEditCreateActions, DefaultListActions, DefaultPagination } from '../lib/defaultConfig'

export const FilmList = props => (
    <List {...props} actions={<DefaultListActions />} pagination={<DefaultPagination />}>
        <Datagrid rowClick="edit">
            <TextField source="id" />
            <TextField source="title" />
        </Datagrid>
    </List>
);

const CustomSimpleForm = props => {
    const t = useTranslate();

    return (<SimpleForm {...props}>
        <TextInput source="title" fullWidth />
        <BooleanInput source="visible" />
        <CustomTextInput source="desc" fullWidth label={t('resources.films.fields.desc', { _: 'Description' })} />
        <TextInput source="platformIvi" fullWidth />
        <TextInput source="platformOkko" fullWidth />
        <TextInput source="platformKinopoisk" fullWidth />
        <TextInput source="platformYoutube" fullWidth />
        <TextInput source="platformNetflix" fullWidth />
        <TextInput source="year" fullWidth />
        <ImageInput source="image" label="Main image" accept="image/*">
            <ImageField source="src" title="title" />
        </ImageInput>
    </SimpleForm>)
};

export const FilmEdit = props => (
    <Edit {...props} actions={<DefaultEditCreateActions />} mutationMode={'pessimistic'}>
        {<CustomSimpleForm />}
    </Edit>
);
export const FilmCreate = props => (
    <Create {...props} actions={<DefaultEditCreateActions />}>
        {<CustomSimpleForm />}
    </Create>
);