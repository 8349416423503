import * as React from "react";
import {
    List,
    Datagrid,
    TextField,
	SimpleForm,
	TextInput,
	BooleanInput,
	Edit,
	Create,
	DateField,
	DateTimeInput,
    useTranslate
} from 'react-admin';
import { DefaultEditCreateActions, DefaultListActions, DefaultPagination } from '../lib/defaultConfig';
import CustomTextInput from "./components/CustomTextInput";

export const DiscountList = props => (
    <List {...props} actions={<DefaultListActions />} pagination={<DefaultPagination />}>
        <Datagrid rowClick="edit">
            <TextField source="id" />
            <TextField source="title" />
			<TextField source="sale" />
			<DateField source="start" showTime locales="ru-RU" />
			<DateField source="end" showTime locales="ru-RU" />
			<TextField source="amount" />
        </Datagrid>
    </List>
);

const CustomSimpleForm = props => {
    const t = useTranslate();

    return (<SimpleForm {...props} redirect="list">
        <BooleanInput source="visible" fullWidth />
        <TextInput source="title" fullWidth />
        <TextInput source="amount" fullWidth />
        <TextInput source="priority" fullWidth />
        <CustomTextInput source="desc" fullWidth label={t('resources.discount.fields.desc', { _: 'Description' })} />
        <TextInput multiline fullWidth source="conditions" />
        <DateTimeInput source="start" locales="ru-RU" />
        <DateTimeInput source="end" locales="ru-RU" />
        <BooleanInput source="summable" fullWidth />
        <BooleanInput source="sale" fullWidth />
    </SimpleForm>)
};

export const DiscountEdit = props => (
    <Edit actions={<DefaultEditCreateActions />} {...props} mutationMode={'pessimistic'}>
        {<CustomSimpleForm />}
    </Edit>
);
export const DiscountCreate = props => (
    <Create actions={<DefaultEditCreateActions />} {...props}>
        {<CustomSimpleForm />}
    </Create>
);