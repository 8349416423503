import * as React from "react";
import CustomTextInput from './components/CustomTextInput';
import {
    List,
    Datagrid,
    TextField,
    Edit,
    Create,
    SimpleForm,
    TextInput,
    CheckboxGroupInput,
    ImageInput,
    ImageField,
    BooleanInput,
    NumberInput,
    useTranslate
} from 'react-admin';
import { DefaultEditCreateActions, DefaultListActions, DefaultPagination, DefaultListPerPage } from '../lib/defaultConfig';

const langs = [
    { id: 'rus', name: 'RUS' },
    { id: 'eng', name: 'ENG' }
]
export const TeacherList = props => (
    <List {...props} actions={<DefaultListActions />} perPage={DefaultListPerPage} pagination={<DefaultPagination />}>
        <Datagrid rowClick="edit">
            <TextField source="id" />
            <TextField source="position" />
            <TextField source="title" />
        </Datagrid>
    </List>
);

const CustomSimpleForm = props => {
    const t = useTranslate();

    return (<SimpleForm {...props}>
        <TextInput source="title" fullWidth />
        <BooleanInput source="visible" />
        <CustomTextInput source="desc" label={t('resources.teacher.fields.desc', { _: 'Description' })} />
        <CustomTextInput source="content" label={t('resources.teacher.fields.content', { _: 'Content' })} />
        <NumberInput source="position" min="0" />
        <ImageField source="image" />
        <ImageInput source="image" label="Main image" accept="image/*">
            <ImageField source="src" />
        </ImageInput>
        <CheckboxGroupInput source="langs" choices={langs} />
    </SimpleForm>)
};

export const TeacherEdit = props => (
    <Edit {...props} actions={<DefaultEditCreateActions/>} mutationMode={'pessimistic'}>
        {<CustomSimpleForm />}
    </Edit>
);
export const TeacherCreate = props => (
    <Create {...props} actions={<DefaultEditCreateActions/>}>
        {<CustomSimpleForm />}
    </Create>
);
