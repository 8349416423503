import * as React from "react";
import {
	Table, TableBody, TableCell, TableContainer, TableHead, TableRow,
	// TablePagination
} from '@material-ui/core'
import Cookies from 'universal-cookie'
import axios from 'axios'
import { useState } from "react";
import { useEffect } from "react";
import { getGraphColors } from '../lib/colors'
import { LineChart } from "../components/Charts";
import { Card, CardBody, CardHeader } from "../components/Card";
import { Row, Col } from "reactstrap";
import { useHistory } from "react-router-dom";
const cookies = new Cookies();
const serverUrl = process.env.REACT_APP_APIURL || ''
const template = process.env.REACT_APP_TEMPLATE || 'academy'


export const StatisticsList = props => {
	const [token] = useState(cookies.get('token'))
	const [statistics, setStatistics] = useState([])
	const [chartLabels, setChartLabels] = useState([])
	const [datasets, setDatasets] = useState([])
	const graphColors = getGraphColors(template)
	const history = useHistory()

	useEffect(() => {
		getData()
	}, [])

	const getData = () => {
		axios.get(`${serverUrl}/report_statistic/course`, {
			headers: { "A-Auth-Token": token },
		}).then((res) => {
			setStatistics(res.data)
			setDatasets(res.data.map(({ courseName, hasAccess, join, complete, completeTest, certificate }, index) => ({
				label: courseName,
				data: [hasAccess, join, complete, completeTest, certificate],
				backgroundColor: graphColors.transparentArray[index > graphColors.colorArray.length ? index - graphColors.colorArray.length : index],
				hoverBackgroundColor: graphColors.hoverArray[index > graphColors.colorArray.length ? index - graphColors.colorArray.length : index],
				borderColor: graphColors.colorArray[index > graphColors.colorArray.length ? index - graphColors.colorArray.length : index],
				borderWidth: 3,
			})
			))
			setChartLabels(['Получили доступ к курсу', 'Зашли в курс', 'Завершили курс', 'Сдали тесты', 'Получили сертификат'])
		})
	}
	return (
		<>
			<Row className="w-100">
				<Col lg={12} md={12} className="py-2">
					<Card>
						<CardHeader>
							Общая статистика по курсам
						</CardHeader>
						<CardBody>
							<LineChart labels={chartLabels} datasets={datasets} stepSize={10} />
						</CardBody>
					</Card>
				</Col>
			</Row>
			<TableContainer sx={{ maxHeight: 440 }}>
				<Table stickyHeader aria-label="sticky table">
					<TableHead>
						<TableRow>
							<TableCell></TableCell>
							<TableCell align="right"></TableCell>
							<TableCell align="right"></TableCell>
							<TableCell align="right"></TableCell>
							<TableCell align="right"></TableCell>
							<TableCell align="right"></TableCell>
						</TableRow>
					</TableHead>
					<TableBody>
						{
							statistics.map((statistic) => (
								<TableRow key={statistic.id} hover onClick={() => history.push(`/report_statistic/course/${statistic.id}`)}>
									<TableCell component="th" scope="row">{statistic.courseName}</TableCell>
									<TableCell align="right">{statistic.hasAccess}</TableCell>
									<TableCell align="right">{statistic.join}</TableCell>
									<TableCell align="right">{statistic.complete}</TableCell>
									<TableCell align="right">{statistic.completeTest}</TableCell>
									<TableCell align="right">{statistic.certificate}</TableCell>
								</TableRow>
							))
						}
					</TableBody>
				</Table>
				{/* <TablePagination
					rowsPerPageOptions={[50, 100, 250]}
					component="div"
					count={statistics.length}
					rowsPerPage={50}
					page={0}
				onPageChange={handleChangePage}
				onRowsPerPageChange={handleChangeRowsPerPage}
				/> */}
			</TableContainer>
		</>
	)
};
