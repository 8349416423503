import * as React from "react";
import {
    List,
    Datagrid,
    TextField,
    FilterLiveSearch,
    useTranslate
} from 'react-admin';
import { DefaultListActions, DefaultPagination, DefaultListPerPage } from '../lib/defaultConfig'
import { DefaultFilterSidebar, IdFilter, CourseIdFilter, CatalogIdFilter, UserGradeIdFilter } from "../components/ListFilters";

const postFilters = [
    <FilterLiveSearch source="userId" label={'global ID'} />
];

export const UserStudyHistoryList = props => {
    const t = useTranslate();
    return (
        <List
            {...props}
            actions={<DefaultListActions/>}
            perPage={DefaultListPerPage}
            pagination={<DefaultPagination />}
            aside={<DefaultFilterSidebar>
                <IdFilter />
                <FilterLiveSearch source="userId" label={t('components.list_filters.globalUserId', { _: 'Global user ID' })} />
                <CourseIdFilter />
                <CatalogIdFilter />
                <UserGradeIdFilter />
            </DefaultFilterSidebar>}
    >
            <Datagrid>
                <TextField source="id" />
                <TextField source="userId" />
                <TextField source="courseId" />
                <TextField source="courseData" />
                <TextField source="catalogId" />
                <TextField source="userGradeId" />
                <TextField source="type" />
                <TextField source="reason" />
                <TextField source="data" />
                <TextField source="date" />
            </Datagrid>
        </List>
    )
};