// in src/Dashboard.js
import React, { useEffect, useState } from "react";
import { useTranslate } from 'react-admin';
// import Card from '@material-ui/core/Card';
// import CardContent from '@material-ui/core/CardContent';
//, BarChart, RadarChart
import { PieChart, LineChart } from "../components/Charts";
import 'bootstrap/dist/css/bootstrap.css'
import { Row, Col } from 'reactstrap'
import axios from 'axios'
import Cookies from 'universal-cookie'
import { Card, CardBody, CardHeader } from "../components/Card";
import { getLang } from "./translate";
const serverUrl = process.env.REACT_APP_APIURL || ''
const template = process.env.REACT_APP_TEMPLATE || 'academy'
const cookies = new Cookies();

const Dashboard = () => {
    const t = useTranslate()
    const token = cookies.get('token')
    const [users, setUsers] = useState([])
    const [courses, setCourses] = useState([])
    const lang = getLang()

    const getUsers = async () => {
        try {
            const users = await axios.get(`${serverUrl}/stats/user_growth`, { headers: { "Accept-Language": lang, "A-Auth-Token": token } })
            const result = {
                labels: users.data.map((user) => (user.monthLong)),
                data: users.data.map((user) => (user.count))
            }
            return result
        } catch (e) {
            return []
        }
    }

    const getCourses = async () => {
        try {
            const courses = await axios.get(`${serverUrl}/stats/course_popularity`, { headers: { "Accept-Language": lang, "A-Auth-Token": token } })
            const result = {
                labels: courses.data.map((course) => (course.courseName)),
                data: courses.data.map((course) => (course.count))
            }
            console.log(result)
            return result
        } catch (e) {
            return []
        }
    }

    useEffect(() => {
        getUsers().then((users) => setUsers(users))
        getCourses().then((courses) => setCourses(courses))
    }, [])

    return (
        <Row className="w-100">
            <Col lg={6} md={12} className="py-2">
                <Card>
                    <CardHeader>
                        {t('pages.dashboard.user_growth')}
                    </CardHeader>
                    <CardBody>
                        <LineChart data={users.data} labels={users.labels} template={template} />
                    </CardBody>
                </Card>
            </Col>
            {/* <Col lg={6} md={12} className="py-2">
                <Card>
                    <CardHeader>
                        BAR CHART
                    </CardHeader>
                    <CardBody>
                        <BarChart template={template} />
                    </CardBody>
                </Card>
            </Col> */}
            {/* <Col lg={6} md={12} className="py-2">
                <Card>
                    <CardHeader>
                        RADAR CHART
                    </CardHeader>
                    <CardBody style={{ width: '60%', margin: '0 auto' }}>
                        <RadarChart template={template} />
                    </CardBody>
                </Card>
            </Col> */}
            <Col lg={6} md={12} className="py-2">
                <Card>
                    <CardHeader>
                        {t('pages.dashboard.course_popularity')}
                    </CardHeader>
                    <CardBody style={{ margin: '0 auto' }}>
                        <PieChart data={courses.data} labels={courses.labels} template={template} />
                    </CardBody>
                </Card>
            </Col>
        </Row>
    );
}
export default Dashboard