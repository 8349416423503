import React from 'react';
import {
	Chart as ChartJS,
	Tooltip,
	Legend,
	RadialLinearScale,
	LinearScale,
	CategoryScale,
} from 'chart.js';
import { Radar } from 'react-chartjs-2';
import { getGraphColors } from '../../lib/colors';

const RadarChart = ({ template, ...props }) => {
	const graphColors = getGraphColors(template)

	ChartJS.register(RadialLinearScale, Tooltip, Legend, LinearScale, CategoryScale);
	const options = {
		onHover: (event, chartElement) => {
			const target = event.native ? event.native.target : event.target;
			target.style.cursor = chartElement[0] ? 'pointer' : 'default';
		},
		responsive: true,
		plugins: {
			legend: {
				position: 'none',
			},
			title: {
				display: false,
			},
			tooltip: {
				displayColors: false
			}
		},
		scale: {
			ticks: {
				beginAtZero: true,
				stepSize: 20
			},
			color: 'pink',
		},
	}
	const data = {
		labels: ['Thing 1', 'Thing 2', 'Thing 3', 'Thing 4', 'Thing 5', 'Thing 6', 'Thing 7'],
		datasets: [
			{
				label: 'First Dataset',
				data: [65, 25, 90, 81, 56, 55, 40],
				backgroundColor: graphColors.primaryTransparent,
				hoverBackgroundColor: graphColors.primaryHover,
				borderColor: graphColors.primary,
				borderWidth: 3,
			},
			{
				label: 'Second Dataset',
				data: [72, 48, 40, 19, 96, 27, 100],
				backgroundColor: graphColors.secondaryTransparent,
				hoverBackgroundColor: graphColors.secondaryHover,
				borderColor: graphColors.secondary,
				borderWidth: 3,
			},
		],
	}
	return <Radar options={options} data={data} />
}

export default RadarChart