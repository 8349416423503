import React, { useEffect, useState } from 'react';
import { Link } from 'react-router-dom';
import { useTranslate } from 'react-admin';
import BookIcon from '@material-ui/icons/Book';
import PeopleIcon from '@material-ui/icons/People';
import SchoolIcon from '@material-ui/icons/School';
import StorageIcon from '@material-ui/icons/Storage';
import { ProSidebar, Menu, MenuItem, SubMenu } from 'react-pro-sidebar';
import './css/sidebar.css';
import Cookies from 'universal-cookie';
import axios from 'axios';

const serverUrl = process.env.REACT_APP_APIURL || '';
const cookies = new Cookies();
export const AdminMenu = () => {
  const t = useTranslate();
  const token = cookies.get('token');
  const [permissions, setPermissions] = useState([]);
  const getPermissions = async () => {
    try {
      const permissions = await axios.get(
        `${serverUrl}/admin_panel/permission_list`,
        { headers: { 'A-Auth-Token': token } }
      );
      return permissions.data;
    } catch (e) {
      return [];
    }
  };
  let role = cookies.get('role');
  role = Array.isArray(role) ? role : [];
  
  const checkRole = (roles) => {
    if (roles === undefined) roles = ['admin'];
    let has = false;
    for (const r of roles) {
      if (~role.indexOf(r)) {
        has = true;
        break;
      }
    }
    return has;
  };
  
  const checkReadPermission = (data) => {
    const { permission, action = 'Read', role } = data
    let has = false;
    const hasRole = !role || checkRole([role])
    if (hasRole) return true
    const foundPermission = permissions.find((p) => p.title === permission);
    if (foundPermission !== undefined) {
      const foundAction = foundPermission.actions.find(
        (a) => a.title === action
      );
      if (foundAction !== undefined && foundAction.value) has = true;
    }
    return has;
  };
  
  const isAccessAvailable = (m) => {
    let isAllow = false;
    if (m.role) {
      // Проверяем через роль
      isAllow = checkRole([m.role]);
    } else {
      isAllow = m.items && (m.items.find((i) => checkReadPermission(i.permission)) !== undefined);
    }
    return isAllow;
  };

  useEffect(() => {
    getPermissions().then((permissions) => setPermissions(permissions));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
  const menu = [
    {
      title: 'menu.materials',
      icon: <BookIcon />,
      items: [
        { title: 'menu.news', link: '/news', permission: 'News' },
        { title: 'menu.terms', link: '/terms', permission: 'Term' },
        { title: 'menu.faq', link: '/faq', permission: 'Faq' },
        { title: 'menu.films', link: '/films', permission: 'Film' },
        {
          title: 'menu.history_of_success',
          link: '/stories',
          permission: 'Story',
        },
        {
          title: 'menu.partners',
          link: '/partner_project',
          permission: 'PartnerProject',
        },
        {
          title: 'menu.pages',
          link: '/page',
          permission: 'Page',
        },
      ],
    },
    {
      title: 'menu.education',
      icon: <SchoolIcon />,
      items: [
        { title: 'menu.catalog', link: '/catalog', permission: 'Catalog' },
        { title: 'menu.programs', link: '/program', permission: 'Program' },
        { title: 'menu.courses', link: '/course', permission: 'Course' },
        {
          title: 'menu.modules',
          link: '/course_theme',
          permission: 'CourseTheme',
        },
        {
          title: 'menu.lessons_tests',
          link: '/course_theme_module',
          permission: 'CourseThemeModule',
        },
        {
          title: 'menu.certificates',
          link: '/certificate',
          permission: 'Certificate',
        },
        {
          title: 'menu.recommendations',
          link: '/course_guidance',
          permission: 'CourseGuidance',
        },
        {
          title: 'menu.developments',
          link: '/events',
          permission: 'Event',
        },
        {
          title: 'menu.webinars',
          link: '/webinars',
          permission: 'Webinar',
        },
        {
          title: 'menu.teachers',
          link: '/teacher',
          permission: 'Teacher',
        },
      ],
    },
    {
      title: 'menu.users',
      icon: <PeopleIcon />,
      items: [
        { title: 'menu.user', link: '/user', permission: 'User' },
        {
          title: 'menu.user_study_history',
          link: '/study_history',
          permission: 'UserStudyHistory',
        },
        {
          title: 'menu.user_grades',
          link: '/user_grade',
          permission: 'UserGrade',
        },
        {
          title: 'menu.user_certificates',
          link: '/user_certificate',
          permission: 'UserCertificate',
        },
        {
          title: 'menu.administrators',
          link: '/manager',
          permission: 'Manager',
        },
        {
          title: 'menu.course_user_manager',
          link: '/course_user_manager',
          permission: 'CourseUserManager',
        },
        {
          title: 'menu.user_courses',
          link: '/catalog_user',
          permission: 'CatalogUser',
        },
        {
          title: 'menu.user_study_history_page',
          link: '/user_study_history_page',
          permission: 'UserStudyHistoryPage',
        },
        {
          title: 'menu.import_purchase',
          link: '/catalog_user_import',
          permission: 'CatalogUserImport',
        },
        {
          title: 'menu.membership',
          link: '/user_membership',
          permission: 'UserMembership',
        },
        {
          title: 'menu.import_membership',
          link: '/membership_user_import',
          permission: 'MembershipUserImport',
        },
      ],
    },
    {
      title: 'menu.guides',
      icon: <StorageIcon />,
      items: [
        {
          title: 'menu.movie_categories',
          link: '/films_genre',
          permission: 'FilmGenre',
        },
        {
          title: 'menu.starting_training',
          link: '/training_assistant',
          permission: 'TrainingAssistant',
        },
      ],
    },
    {
      title: 'menu.curator',
      icon: <StorageIcon />,
      items: [
        {
          title: 'menu.checking_assignments',
          link: '/course_file_task',
          permission: 'CourseFileTask',
        },
      ],
    },
    {
      title: 'menu.reports',
      icon: <StorageIcon />,
      role: 'admin',
      items: [
        {
          title: 'menu.reports_certificates',
          link: '/report_certificate',
          role: 'admin',
        },
        {
          title: 'menu.learning_progress',
          link: '/report_complete',
          role: 'admin',
        },
        {
          title: 'menu.admission_to_goods',
          link: '/report_catalog_user',
          role: 'admin',
        },
        {
          title: 'menu.statistics',
          link: '/report_statistic/course',
          role: 'admin',
        },
        {
          title: 'menu.user_path',
          link: '/report_user_achi',
          role: 'admin',
        },
        {
          title: 'menu.manager_task_file',
          link: '/report_manager_task_file',
          role: 'admin',
        },
      ],
    },
    {
      title: 'menu.marketing',
      icon: <StorageIcon />,
      items: [
        {
          title: 'menu.discount',
          link: '/discount',
          permission: 'Discount',
        },
      ],
    },
    {
      title: 'menu.system',
      icon: <StorageIcon />,
      role: 'admin',
      items: [
        {
          title: 'menu.roles',
          link: '/manager_role',
          role: 'admin',
        },
        {
          title: 'menu.grades',
          link: '/grade',
          role: 'admin',
        },
        {
          title: 'menu.action_history',
          link: '/manager_log',
          role: 'admin',
        },
        {
          title: 'menu.system_settings',
          link: '/system_setting',
          role: 'admin',
        },
        {
          title: 'menu.api_keys',
          link: '/access_api_key',
          role: 'admin',
        },
        {
          title: 'menu.letter_templates',
          link: '/email_template',
          role: 'admin',
        },
        {
          title: 'menu.system_events',
          link: '/system_event_type',
          role: 'admin',
        },
        {
          title: 'menu.docs',
          link: '/doc',
          role: 'admin',
        },
        {
          title: 'menu.develop_dashboard',
          link: '/develop_dashboard',
          role: 'admin',
        },
        {
          title: 'menu.system_notifications',
          link: '/system_notifications',
          role: 'admin',
        },
      ],
    },
  ];
  const res = (
    <div>
      <ProSidebar style={{ paddingTop: '20px' }}>
        <Menu iconShape="square">
          {menu.map((m) => (
            <>
              {isAccessAvailable(m) ? (
                <SubMenu title={t(m.title)} icon={m.icon}>
                  {m.items.map((i) =>
                    checkReadPermission({ permission: i.permission, role: i.role }) ? (
                      <MenuItem>
                        {t(i.title)}
                        <Link to={i.link} />
                      </MenuItem>
                    ) : (
                      ''
                    )
                  )}
                </SubMenu>
              ) : (
                ''
              )}
            </>
          ))}
        </Menu>
      </ProSidebar>
    </div>
  );
  return res;
};
