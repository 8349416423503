import * as React from 'react';
import { useSetLocale, TopToolbar, CreateButton, ExportButton, FilterButton, Button } from 'react-admin';
import classes from '../Model/components/Components.module.css'
const CustomListActions = (props) => {
	const langs = ["ru", "en", "es", "kk", "zh", "de", "it", "hi", "vi", "mn"]
	const createButton = props.create ? props.create : true
	const filterButton = props.filter ? props.filter : false
	
	const changeLang = (lang) => {
		console.log('xxx2')
		// localStorage.setItem('localeContent', lang)
		// window.location.reload()
	};
	return (
		<TopToolbar className={classes.display__block}>
			<div className={classes.flex__between}>
				<div>
					{
						langs.map((lang) => (
							<Button
								className={`${classes.label_no_padding} ${localStorage.getItem('localeContent') === lang ? classes.active__btn : ''}`}
								onClick={() => changeLang(lang)}
								label={lang}
							>
							</Button>
						))
					}
				</div>
				<div>
					{filterButton ? <FilterButton /> : ''}
					{createButton ? <CreateButton /> : ''}
					<ExportButton />
				</div>
			</div>
		</TopToolbar>
	);
};

export default CustomListActions;