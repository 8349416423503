import * as React from "react";
import CustomTextInput from './components/CustomTextInput';
import {
    List,
    Datagrid,
    TextField,
    Edit,
    Create,
    SimpleForm,
    TextInput,
    DateField,
    BooleanInput,
    DateTimeInput,
    RichTextField,
    ImageInput,
    ImageField,
    ReferenceArrayInput,
    AutocompleteArrayInput,
    useTranslate
} from 'react-admin';
import { DefaultEditCreateActions, DefaultListActions, DefaultPagination, DefaultListPerPage } from '../lib/defaultConfig';
import { DefaultFilterSidebar, IdFilter } from "../components/ListFilters";

export const WebinarList = props => (
    <List {...props} actions={<DefaultListActions />} perPage={DefaultListPerPage} pagination={<DefaultPagination />}
            aside={<DefaultFilterSidebar><IdFilter /></DefaultFilterSidebar>}>
        <Datagrid rowClick="edit">
            <TextField source="id" />
            <TextField source="title" />
            <RichTextField source="desc" />
            <DateField source="start" showTime locales="ru-RU" />
            <DateField source="end" showTime locales="ru-RU" />
        </Datagrid>
    </List>
);

const CustomSimpleForm = props => {
    const t = useTranslate();

    return (<SimpleForm {...props}>
        <TextInput source="title" fullWidth />
        <BooleanInput source="visible" />
        <CustomTextInput source="desc" label={t('resources.webinars.fields.desc', { _: 'Description' })} />
        <CustomTextInput source="content" label={t('resources.webinars.fields.content', { _: 'Content' })} />
        <ImageInput source="image" accept="image/*">
            <ImageField source="src" title="title" />
        </ImageInput>
        <TextInput source="url" fullWidth />
        <DateTimeInput source="start" locales="ru-RU" />
        <DateTimeInput source="end" locales="ru-RU" />
        <BooleanInput source="allDay" />
        {/* <ReferenceInput source="visibleInLang" label={labels.visibleInLang} reference="langs" fullWidth>
            <SelectInput optionText="title" />
        </ReferenceInput> */}
        <ReferenceArrayInput source="visibleInLang" reference="langs" sort={{ field: 'title', order: 'ASC' }} perPage={1000} fullWidth>
            <AutocompleteArrayInput optionText="title" optionValue="id" />
        </ReferenceArrayInput>
    </SimpleForm>)
};

export const WebinarEdit = props => (
    <Edit {...props} actions={<DefaultEditCreateActions />} mutationMode={'pessimistic'}>
        {<CustomSimpleForm />}
    </Edit>
);
export const WebinarCreate = props => (
    <Create {...props} actions={<DefaultEditCreateActions />}>
        {<CustomSimpleForm />}
    </Create>
);