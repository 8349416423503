import * as React from "react";
import {
    List,
    Datagrid,
    TextField,
    Edit,
    Create,
    SimpleForm,
    TextInput,
    BooleanInput,
    BooleanField,
} from 'react-admin';
import { DefaultEditCreateActions, DefaultListActions, DefaultPagination } from '../lib/defaultConfig'

export const AccessApiKeyList = props => (
    <List {...props} actions={<DefaultListActions />} pagination={<DefaultPagination />}>
        <Datagrid rowClick="edit">
            <TextField source="id" />
            <TextField source="name" />
            <BooleanField source="active" />
        </Datagrid>
    </List>
);
const simpleForm =
    <SimpleForm>
        <TextInput source="name" fullWidth />
        <BooleanInput source="active" />
        <TextInput source="key" fullWidth />
        <TextInput source="data" fullWidth />

    </SimpleForm>
export const AccessApiKeyEdit = props => (
    <Edit {...props} actions={<DefaultEditCreateActions />} mutationMode={'pessimistic'}>
        {simpleForm}
    </Edit>
);
export const AccessApiKeyCreate = props => (
    <Create {...props} actions={<DefaultEditCreateActions />}>
        {simpleForm}
    </Create>
);
