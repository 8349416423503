import * as React from "react";
import CustomTextInput from './components/CustomTextInput';
import {
    List,
    Datagrid,
    TextField,
    Edit,
    Create,
    SimpleForm,
    TextInput,
    ImageInput,
    ImageField,
    NumberInput,
    BooleanInput,
    useTranslate
} from 'react-admin';
import { DefaultEditCreateActions, DefaultListActions, DefaultPagination, DefaultListPerPage } from '../lib/defaultConfig';

export const TrainingAssistantList = props => (
    <List {...props} actions={<DefaultListActions />} perPage={DefaultListPerPage} pagination={<DefaultPagination />}>
        <Datagrid rowClick="edit">
            <TextField source="id" />
            <TextField source="title" />
        </Datagrid>
    </List>
);

const CustomSimpleForm = props => {
    const t = useTranslate();

    return (<SimpleForm {...props}>
        <TextInput source="title" fullWidth />
        <BooleanInput source="visible" />
        <CustomTextInput source="content" fullWidth label={t('resources.training_assistant.fields.content', { _: 'Content' })} />	
        <NumberInput source="position" min="0" />
        <ImageInput source="image" label="Related pictures" accept="image/*">
            <ImageField source="src" title="title" />
        </ImageInput>
    </SimpleForm>)
};

export const TrainingAssistantEdit = props => (
    <Edit {...props} actions={<DefaultEditCreateActions/>} mutationMode={'pessimistic'}>
        {<CustomSimpleForm />}
    </Edit>
);
export const TrainingAssistantCreate = props => (
    <Create {...props} actions={<DefaultEditCreateActions/>}>
        {<CustomSimpleForm />}
    </Create>
);
