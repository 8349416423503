import * as React from "react";
import {
    List,
    Show,
    SimpleShowLayout,
    Datagrid,
    TextField
} from 'react-admin';
import { DefaultListActions, DefaultShowActions, DefaultPagination, DefaultListPerPage } from '../lib/defaultConfig';
import { DefaultFilterSidebar, IdFilter, UserIdFilter, UserEmailFilter } from "../components/ListFilters";

export const UserList = props => {
    return (
        <List
            {...props}
            actions={<DefaultListActions/>}
            perPage={DefaultListPerPage}
            pagination={<DefaultPagination />}
            aside={<DefaultFilterSidebar>
                <IdFilter />
                <UserIdFilter global />
                <UserEmailFilter />
            </DefaultFilterSidebar>}
        >
            <Datagrid rowClick="show">
                <TextField source="id" />
                <TextField source="userId" />
                <TextField source="userName" />
                <TextField source="userEmail" />
            </Datagrid>
        </List>
    )
};

export const UserShow = props => (
    <Show {...props} actions={<DefaultShowActions />}>
        <SimpleShowLayout>
            <TextField source="id" fullWidth />
            <TextField source="userId" fullWidth />
            <TextField source="userName" fullWidth />
            <TextField source="userEmail" fullWidth />
        </SimpleShowLayout>
    </Show>
);