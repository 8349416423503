import React from 'react';
import {
	Chart as ChartJS,
	Tooltip,
	Legend,
	PointElement,
	LineElement,
	LinearScale,
	Filler,
	CategoryScale
} from 'chart.js';
import { Line } from 'react-chartjs-2';
import { getGraphColors } from '../../lib/colors';
import classes from './Chart.module.css'

const LineChart = ({ template, ...props }) => {
	const graphColors = getGraphColors(template)

	ChartJS.register(PointElement, LinearScale, LineElement, Filler, Tooltip, Legend, CategoryScale);
	const options = {
		onHover: (event, chartElement) => {
			const target = event.native ? event.native.target : event.target;
			target.style.cursor = chartElement[0] ? 'pointer' : 'default';
		},
		responsive: true,
		maintainAspectRatio: false,
		plugins: {
			legend: {
				position: 'none',
			},
			title: {
				display: false,
			},
			tooltip: {
				displayColors: false
			}
		},
		scales: {
			y: {
				ticks: {
					stepSize: props.stepSize || 1,
				}
			}
		}

	};
	const labels = props.labels || [];
	const data = {
		labels,
		datasets: props.datasets || [
			{
				label: 'Количество пользователей',
				data: props.data || [],
				backgroundColor: graphColors.secondaryTransparent,
				hoverBackgroundColor: graphColors.secondaryHover,
				fill: true,
				tension: 0.3,
				borderColor: graphColors.secondary,
				borderWidth: 3,
			},
		],
	};
	return <Line className={classes.canvas} options={options} data={data} />;
}

export default LineChart