import * as React from "react";
import CustomTextInput from './components/CustomTextInput';
import {
    List,
    Datagrid,
    TextField,
    Edit,
    Create,
    SimpleForm,
    TextInput,
    ImageInput,
    ImageField,
    DateTimeInput,
    BooleanInput,
    useTranslate
} from 'react-admin';
import { DefaultEditCreateActions, DefaultListActions, DefaultPagination } from '../lib/defaultConfig';

export const PageList = props => (
    <List {...props} actions={<DefaultListActions />} pagination={<DefaultPagination />}>
        <Datagrid rowClick="edit">
            <TextField source="id" />
            <TextField source="title" />
            <TextField source="uri" />
        </Datagrid>
    </List>
);

const CustomSimpleForm = props => {
    const t = useTranslate();

    return (<SimpleForm {...props}>
        <TextInput source="title" fullWidth />
        <TextInput source="uri" fullWidth />
        <BooleanInput source="visible" />
        <CustomTextInput source="desc" label={t('resources.page.fields.desc', { _: 'Description' })} />
        <CustomTextInput source="content" label={t('resources.page.fields.content', { _: 'Content' })} />
        <ImageInput source="image" accept="image/*">
            <ImageField source="src" title="Main image" />
        </ImageInput>
        <DateTimeInput source="date" />
    </SimpleForm>)
};

export const PageEdit = props => (
    <Edit {...props} actions={<DefaultEditCreateActions />} mutationMode={'pessimistic'}>
        {<CustomSimpleForm />}
    </Edit>
);
export const PageCreate = props => (
    <Create {...props} actions={<DefaultEditCreateActions />}>
        {<CustomSimpleForm />}
    </Create>
);
