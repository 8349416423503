import * as React from "react";
import {
    List,
    Datagrid,
    TextField,
    Edit,
    Create,
    SimpleForm,
    ReferenceInput,
    ReferenceField,
    SelectInput,
    NumberInput,
    DateTimeInput
} from 'react-admin';
import { DefaultEditCreateActions, DefaultListActions, DefaultPagination, DefaultListPerPage } from '../lib/defaultConfig'
import { DefaultFilterSidebar, GlobalUserIdFilter, UserIdFilter, CatalogIdFilter } from "../components/ListFilters";

export const CatalogUserList = props => (
    <List {...props} actions={<DefaultListActions />} perPage={DefaultListPerPage} pagination={<DefaultPagination />} 
        aside={<DefaultFilterSidebar>
            <UserIdFilter />
            <GlobalUserIdFilter />
            <CatalogIdFilter />
        </DefaultFilterSidebar>}
    >
        <Datagrid rowClick="edit">
            <TextField source="id" />
            <TextField source="userId" />
            {/* <ReferenceField source="userId" reference="user">
                <TextField source="id" />
            </ReferenceField> */}
            <TextField source="globalUserId" />
            <TextField source="catalogId" />
            {/* <ReferenceField source="catalogId" reference="catalog">
                <TextField source="id" />
            </ReferenceField> */}
        </Datagrid>
    </List>
);
const simpleForm =
    <SimpleForm>
        <NumberInput source="globalUserId" min="0" />
        <NumberInput source="userId" min="0" />
        <ReferenceInput source="catalogId" reference="catalog">
            <SelectInput optionText="id" />
        </ReferenceInput>
        <DateTimeInput source="start" locales="ru-RU" />
        <DateTimeInput source="end" locales="ru-RU" />
    </SimpleForm>
export const CatalogUserEdit = props => (
    <Edit {...props} actions={<DefaultEditCreateActions />} mutationMode={'pessimistic'}>
        {simpleForm}
    </Edit>
);
export const CatalogUserCreate = props => (
    <Create {...props} actions={<DefaultEditCreateActions />}>
        {simpleForm}
    </Create>
);
