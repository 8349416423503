import customJsonServerProvider from './customJsonServerProvider';

const dataProvider = customJsonServerProvider(process.env.REACT_APP_APIURL);
const imageFields = ['image','imageWide']
const fileFields = ['file']
const myDataProvider = {
	...dataProvider,
	update: async (resource, params) => {
		let hasImages = false
		let hasFiles = false
		let images = []
		let files = []

		imageFields.forEach(imageField => {
			if (
				params.data.hasOwnProperty(imageField)
				&& params.data[imageField] !== null
				&& typeof params.data[imageField] === 'object'
				&& params.data[imageField].hasOwnProperty('rawFile')
			) {
				hasImages = true
				params.data[imageField].field = imageField
				images.push(params.data[imageField])
			}
		})

		fileFields.forEach(fileField => {
			if(
				params.data.hasOwnProperty(fileField)
				&& params.data[fileField] !== null
				&& Array.isArray(params.data[fileField])
			) {
				params.data[fileField].forEach((element,i) => {
					if (element.hasOwnProperty('rawFile')) {
						if (typeof element === 'object') {
							hasFiles = true
							params.data[fileField][i].field = fileField
							files.push(params.data[fileField][i])
						}
					}
				});
			}
		})

		if (hasImages || hasFiles){
			// let promiseConvert = []
			let convertedImages = []
			for(const image of images){
				if(image.rawFile instanceof File){
					convertedImages.push(await convertFileToBase64(image))
				}
			}
			let convertedFiles = []
			for(const file of files){
				if(file.rawFile instanceof File){
					convertedFiles.push(await convertFileToBase64(file))
				}
			}
			let data = {...params.data}
			if(hasImages)
				data.images = [...convertedImages]
			if(hasFiles)
				data.files = [...convertedFiles]

			return dataProvider.update(resource, {
				...params,
				data
			})
			// images.forEach(image => {
			// 	if(image.rawFile instanceof File){
			// 		promiseConvert.push(convertFileToBase64(image))
			// 	}
			// });
			// return Promise.all(promiseConvert).then((convertedImages)=>{
			// 	dataProvider.update(resource, {
			// 		...params,
			// 		data: {
			// 			...params.data,
			// 			images: [
			// 				...convertedImages
			// 			]
			// 		}
			// 	})
			// })
		} else {
			return dataProvider.update(resource, params);
		}

	},
};

const convertFileToBase64 = file =>
	new Promise((resolve, reject) => {
		const reader = new FileReader();
		
		reader.onload = () => {
			file.base64 = reader.result
			resolve(file)
		};
		reader.onerror = reject;

		reader.readAsDataURL(file.rawFile);
	});

export default myDataProvider;