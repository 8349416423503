import React, { Component } from "react"
import Card from "@material-ui/core/Card"
import axios from "axios"
import { FormGroup, Button, TextField, Accordion, AccordionSummary, Typography, AccordionDetails, ButtonGroup, Link } from "@material-ui/core"
import classes from "../Model/components/Components.module.css"
import Cookies from "universal-cookie"
import { withTranslate } from "react-admin"
import Dialog from "../components/Dialog"

const cookies = new Cookies()

const serverUrl = process.env.REACT_APP_APIURL || ""

class App extends Component {
    handleOpen = () => {
        this.setState({ dialog: true })
    }

    handleClose = () => {
        this.setState({ dialog: false })
    }

    state = {
        selectedFile: null,
        page: 1,
        rows: [],
        rowComments: [],
        rowGrades: [],
        dialog: false,
        message: null,
        globalUserId: null,
        email: null,
        login: null,
        token: cookies.get("token"),
    }

    componentDidMount = () => {}

    handleChange = (event) => {
        this.setState({ [event.target.name]: event.target.value })
    }
    sendMethod = () => {
        axios
            .post(
                `${serverUrl}/admin_panel/user_study_history`,
                {
                    globalUserId: this.state.globalUserId,
                    email: this.state.email,
                    login: this.state.login,
                },
                {
                    headers: { "A-Auth-Token": this.state.token },
                }
            )
            .then((data) => {
                this.setState({ message: data.data.message })
                if (data.data.message) {
                    this.handleOpen()
                }
            })
    }

    render() {
        const { translate } = this.props
        return (
            <Card>
                <Dialog message={this.state.message} handleOpen={this.handleOpen} handleClose={this.handleClose} open={this.state.dialog} />
                <h2>История обучения пользователя</h2>

                <FormGroup row className={`${classes.flex__inputs} ${classes.align__center} `}>
                    <TextField placeholder="Global UserID" name="globalUserId" variant="filled" fullWidth className={classes.question_title__input} onChange={this.handleChange} />
                    <TextField placeholder="Email" name="email" variant="filled" fullWidth className={classes.question_title__input} onChange={this.handleChange} />
                    <TextField placeholder="Login" name="login" variant="filled" fullWidth className={classes.question_title__input} onChange={this.handleChange} />
                    <Button variant="contained" fullWidth color={"primary"} onClick={() => this.sendMethod("getStudyHistory")}>
                        Поиск
                    </Button>
                </FormGroup>

                <ButtonGroup> </ButtonGroup>
            </Card>
        )
    }
}
export default withTranslate(App)
