import * as React from "react";
import CustomTextInput from './components/CustomTextInput';
import AccordionInput from './components/AccordionInput';
import TestInput from './components/TestInput';
import {
    List,
    Datagrid,
    TextField,
    Edit,
    Create,
    SimpleForm,
    TextInput,
    RadioButtonGroupInput,
    NumberInput,
    BooleanInput,
    FileInput,
    FileField
} from 'react-admin';
import { DefaultEditCreateActions, DefaultListActions, DefaultPagination } from '../lib/defaultConfig'

const types = [
    { id: 'lesson', name: 'Урок' },
    { id: 'test', name: 'Тест' }
]

export const DocList = props => (
    <List {...props} actions={<DefaultListActions />} pagination={<DefaultPagination />}>
        <Datagrid rowClick="edit">
            <TextField source="id" />
            <TextField source="title" />
        </Datagrid>
    </List>
);
const simpleForm =
    <SimpleForm>
        <TextInput source="title" fullWidth />
        <CustomTextInput source="desc" fullWidth />
        <FileInput source="file" accept=".pdf,.jpg" multiple={true} fullWidth >
            <FileField source="src" title="title" />
        </FileInput>
    </SimpleForm>
export const DocEdit = props => (
    <Edit {...props} actions={<DefaultEditCreateActions />} mutationMode={'pessimistic'}>
        {simpleForm}
    </Edit>
)
export const DocCreate = props => (
    <Create {...props} actions={<DefaultEditCreateActions />}>
        {simpleForm}
    </Create>
)