import * as React from "react";
import {
    List,
    Datagrid,
    TextField,
    ReferenceField,
} from 'react-admin';
import { DefaultListActions, DefaultPagination } from '../lib/defaultConfig'

export const ReportManagerTaskFileList = props => (
    <List {...props} actions={<DefaultListActions />} pagination={<DefaultPagination />}>
        <Datagrid>
            <ReferenceField source="id" reference="manager">
                <TextField source="id" />
            </ReferenceField>
            <TextField source="name" />
            <TextField source="login" />
            <TextField source="taskFilesProcessing" />
            <TextField source="taskFilesOpen" />
            <TextField source="taskFilesClose" />
            <TextField source="taskFilesTotal" />
        </Datagrid>
    </List>
);