import * as React from "react";
import CustomTextInput from './components/CustomTextInput';

import {
    List,
    Datagrid,
    TextField,
    Edit,
    Create,
    SimpleForm,
    TextInput,
    ImageInput,
    ImageField,
    BooleanInput,
    useTranslate
} from 'react-admin';
import { DefaultEditCreateActions, DefaultListActions, DefaultPagination, DefaultListPerPage } from '../lib/defaultConfig';
import { DefaultFilterSidebar, IdFilter } from "../components/ListFilters";

export const CertificateList = props => (
    <List {...props} actions={<DefaultListActions />} perPage={DefaultListPerPage} pagination={<DefaultPagination />}
            aside={<DefaultFilterSidebar><IdFilter /></DefaultFilterSidebar>}>
        <Datagrid rowClick="edit">
            <TextField source="id" />
            <TextField source="title" />
        </Datagrid>
    </List>
);
const CustomSimpleForm = props => {
    const t = useTranslate();

    return (<SimpleForm {...props} redirect="list">
        <TextInput source="title" fullWidth />
        <BooleanInput source="visible" fullWidth />
        <CustomTextInput source="desc" fullWidth label={t('resources.certificate.fields.desc', { _: 'Description' })} />
        <ImageField source="image" />
        <ImageInput source="image" accept="image/*">
            <ImageField source="src" title="title" />
        </ImageInput>

        <TextInput multiline fullWidth source="config" />
    </SimpleForm>)
};

export const CertificateEdit = props => (
    <Edit actions={<DefaultEditCreateActions />} {...props} mutationMode={'pessimistic'}>
        {<CustomSimpleForm />}
    </Edit>
);
export const CertificateCreate = props => (
    <Create actions={<DefaultEditCreateActions />} {...props}>
        {<CustomSimpleForm />}
    </Create>
);
