import React, { useEffect, useRef } from 'react';
import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
import DOMPurify from 'dompurify';

export default function ScrollDialog({ message, open, handleOpen, handleClose, isComponent, ...props }) {

	const descriptionElementRef = useRef(null);

	useEffect(() => {
		if (open) {
			const { current: descriptionElement } = descriptionElementRef;
			if (descriptionElement !== null) {
				descriptionElement.focus();
			}
		}
	}, [open]);

	return (
		<div>
			<Dialog
				//fullWidth={true}
				//maxWidth={'lg'}
				open={open}
				onClose={handleClose}
				scroll={'paper'}
				aria-labelledby="scroll-dialog-title"
				aria-describedby="scroll-dialog-description"
			>
				<DialogTitle id="scroll-dialog-title">Сообщение</DialogTitle>
				<DialogContent>
					{
						!isComponent ?
						<DialogContentText
							id="scroll-dialog-description"
							ref={descriptionElementRef}
							tabIndex={-1}
							dangerouslySetInnerHTML={{__html: DOMPurify.sanitize(message)}}
						/>
						:
						<DialogContentText
							id="scroll-dialog-description"
							ref={descriptionElementRef}
							tabIndex={-1}
							children={message}
						/>
					}
				</DialogContent>
				<DialogActions>
					<Button onClick={handleClose}>Закрыть</Button>
				</DialogActions>
			</Dialog>
		</div>
	);
}