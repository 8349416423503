// in src/Foo.js
import React, { Component } from 'react'
import Card from '@material-ui/core/Card'
import axios from 'axios'
import {
  FormGroup,
  Button,
  TextField,
  Accordion,
  AccordionSummary,
  Typography,
  AccordionDetails,
  Link
} from '@material-ui/core'
import { Stack } from '@mui/material'
import classes from '../Model/components/Components.module.css'
import ExpandMoreIcon from '@material-ui/icons/ExpandMore'
import HourglassEmptyIcon from '@material-ui/icons/HourglassEmpty'
import AttachFileIcon from '@material-ui/icons/AttachFile';
import styled from 'styled-components'
import { formatDate, getHours } from '../lib/lib'
import Cookies from 'universal-cookie'
import { withTranslate } from 'react-admin';
import Dialog from '../components/Dialog'
const cookies = new Cookies();

const serverUrl = process.env.REACT_APP_APIURL || ''
const fileUrl = process.env.REACT_APP_IMAGEURL || ''

const StyledLink = styled(Link)`
  font-weight: 600;
  color: #2D0F19;
  text-align: left;
`

const CommentWrapper = styled.div`
    padding-top: 20px;
`

const Head = styled.div`
    display: flex;
    justify-content: space-between;
    .from {
        font-weight: 600;
        color: #2D0F19;
    }
    .date {
        color: #7E7E7E;
    }
`

const Comment = styled.div`
    padding-top: 10px;
    color: #7E7E7E;
`

const Attachments = styled.div`
    display: flex;
    padding-top: 10px;
`

const File = styled.div`
    display: flex;
    align-items: center;
    padding-right: 10px;
    span {
        padding-left: 4px;
    }
    &:hover {
        cursor: pointer;
    }
`


class App extends Component {
  handleOpen = () => {
    this.setState({ dialog: true })
  }

  handleClose = () => {
    this.setState({ dialog: false })
  }

  state = {
    selectedFile: null,
    page: 1,
    rows: [],
    rowComments: [],
    rowGrades: [],
    dialog: false,
    message: null,
    globalUserId: null,
    courseId: null,
    token: cookies.get('token')
  };

  onFileChange = event => {
    this.setState({ selectedFile: event.target.files[0] });
  };
  componentDidMount = () => {
    console.log(this.state.token)
    this.getData()
  }
  getData = () => {
    const locale = localStorage.getItem('locale')
    axios.post(`${serverUrl}/admin_panel/reporter`,{
        method: 'list'
      }, {
      headers: { "A-Auth-Token": this.state.token, 'Accept-Language': locale },
    }).then((res) => {
      this.setState({ rows: res.data.rows })
    })
  }

  handleChange = (event) => {
    this.setState({ [event.target.name]: event.target.value })
  }
  sendMethod = (method) => {
    axios.post(`${serverUrl}/admin_panel/develop_dashboard`,
      { 
        method, 
        globalUserId: this.state.globalUserId,
        courseId:this.state.courseId
      }, {
      headers: { "A-Auth-Token": this.state.token },
    }).then((data) => {
      console.log(data)
      this.setState({ message: data.data.message })
      if (data.data.message) this.handleOpen()
    })
  }

  taskFileOpenRequest = (id) => {
    axios.post(`${serverUrl}/admin_panel/course_task_file_open`,
      {
        taskFileId: id,
      }, {
      headers: { "A-Auth-Token": this.state.token },
    })
  }

  render() {
    const { translate } = this.props;
    return (
      <Card>
        <Dialog
          message={this.state.message}
          handleOpen={this.handleOpen}
          handleClose={this.handleClose}
          open={this.state.dialog}
        />
        <h2>Reporter</h2>
        {this.state.rows.length ? this.state.rows.map((row, index) =>
          <Accordion>
            <AccordionSummary expandIcon={<ExpandMoreIcon />}>
              <HourglassEmptyIcon color="primary" />
              <Typography>{row.name} - {row.file} </Typography>
            </AccordionSummary>
            <AccordionDetails className={classes.flex__column}>
              
              {
                row.comments ?
                  row.comments.map(({ id, from, createdAt, comment, files }) => (
                    <CommentWrapper>
                      <Head>
                        {
                          from === 'user' ?
                            <span className='md-font-size from'>{translate('pages.course_file_task.user')}</span> :
                            <span className='md-font-size from'>{translate('pages.course_file_task.teacher')}</span>
                        }
                        <span className='md-font-size date'>{`${formatDate(createdAt)} ${getHours(createdAt)}`}</span>
                      </Head>
                      <Comment className='md-font-size'>
                        {comment}
                      </Comment>
                      <Attachments>
                        {
                          files ?
                            files.map((file, index) => (
                              <File
                                key={`file-${index}`}
                                onClick={() => window.open(`${fileUrl}/${file}`, "_blank")}
                              >
                                <AttachFileIcon size={16} />
                                <span className='sm-font-size'>{translate('pages.course_file_task.task_file')}</span>
                              </File>
                            )) : ''
                        }
                      </Attachments>
                    </CommentWrapper >
                  )) : ''
              }
              <h4>Данные об отчете</h4>
              
            </AccordionDetails>

          </Accordion>) : translate('pages.course_file_task.no_answers_to_tasks')}

          <FormGroup className={`${classes.flex__inputs} ${classes.align__center} `}>
              <Stack mb={1} sx={{ width: '100%' }} spacing={1} pl={1} pr={1}>
                <TextField
                  placeholder="Global UserID"
                  name="globalUserId"
                  variant="filled"
                  fullWidth
                  className={classes.question_title__input}
                  onChange={this.handleChange}
                />
                <TextField
                  placeholder="Курс ID"
                  name="courseId"
                  variant="filled"
                  fullWidth
                  className={classes.question_title__input}
                  onChange={this.handleChange}
                />
              </Stack>
            </FormGroup>
              
              <Stack m={1} spacing={1} direction="row">
                <Button
                  variant="contained"
                  onClick={() => this.sendMethod('clearCourseComplete')}
                >
                  Сбросить прогресс обучения
                </Button>{' '}
                <Button
                  variant="contained"
                  onClick={() => this.sendMethod('clearGrades')}
                >
                  Сбросить все грейды
                </Button>
                <Button
                  variant="contained"
                  onClick={() => this.sendMethod('clearStudyHistory')}
                >
                  Сбросить историю обучения
                </Button>
                <Button
                  variant="contained"
                  onClick={() => this.sendMethod('clearCertificate  ')}
                >
                  Сбросить все сертификаты у пользователя
                </Button>
              </Stack>
              
              <Stack m={1} spacing={1} direction="row">
                <Button
                  variant="contained"
                  onClick={() => this.sendMethod('reloadSettings')}
                >
                  Перезагрузить настройки
                </Button>
                <Button
                  variant="contained"
                  onClick={() => this.sendMethod('clearSessions')}
                >
                  Сбросить сессии
                </Button>
              </Stack>
      </Card>
    );
  }
}
export default withTranslate(App)