import * as React from "react";
import {
    List,
    Datagrid,
    TextField,
} from 'react-admin';
import { DefaultListActions, DefaultPagination } from '../lib/defaultConfig'


export const LangList = props => (
    <List {...props} actions={<DefaultListActions />} pagination={<DefaultPagination />}  >
        <Datagrid >
            <TextField source="id" />
            <TextField source="title" />
        </Datagrid>
    </List >
);
