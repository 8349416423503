import React from 'react';
import ReactDOM from 'react-dom';

import App from './App';
const template = process.env.REACT_APP_TEMPLATE || 'academy'
import(`./${template}.css`)

ReactDOM.render(
    <App />,
    document.getElementById('root')
);
