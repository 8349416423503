import * as React from "react";
import CustomTextInput from './components/CustomTextInput';
import {
    List,
    Datagrid,
    TextField,
    Edit,
    Create,
    SimpleForm,
    TextInput,
    ImageField,
    ImageInput,
    useTranslate
} from 'react-admin';
import { DefaultEditCreateActions, DefaultListActions, DefaultPagination } from '../lib/defaultConfig';

const CustomSimpleForm = props => {
    const t = useTranslate();

    return (<SimpleForm {...props}>
        <TextInput source="name" fullWidth />
        <CustomTextInput source="desc" fullWidth label={t('resources.grade.fields.desc', { _: 'Description' })} />
        <TextInput source="position" fullWidth />
        <TextInput source="duration" fullWidth />
        <ImageField source="image" />
        <ImageInput source="image" accept="image/*">
            <ImageField source="src" title="title" />
        </ImageInput>
    </SimpleForm>)
};

export const GradeList = props => (
    <List {...props} actions={<DefaultListActions />} pagination={<DefaultPagination />}>
        <Datagrid rowClick="edit">
            <TextField source="id" />
            <TextField source="name" />
            <TextField source="position" />
        </Datagrid>
    </List>
);
export const GradeEdit = props => (
    <Edit {...props} actions={<DefaultEditCreateActions />} mutationMode={'pessimistic'}>
        {<CustomSimpleForm />}
    </Edit>
);
export const GradeCreate = props => (
    <Create {...props} actions={<DefaultEditCreateActions />}>
        {<CustomSimpleForm />}
    </Create>
);
