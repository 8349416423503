import * as React from "react";
import CustomTextInput from './components/CustomTextInput';
import AccordionInput from './components/AccordionInput';
import LessonTree from './components/LessonTree';
import {
    List,
    Datagrid,
    TextField,
    Edit,
    Create,
    SimpleForm,
    TextInput,
    NumberInput,
    ReferenceInput,
    SelectInput,
    ImageInput,
    ImageField,
    BooleanInput,
    ReferenceArrayInput,
    AutocompleteArrayInput,
    useTranslate
} from 'react-admin';
import { DefaultEditCreateActions, DefaultListActions, DefaultPagination, DefaultListPerPage } from '../lib/defaultConfig';
import { DefaultFilterSidebar, IdFilter } from "../components/ListFilters";

export const CourseList = props => (
    <List {...props} actions={<DefaultListActions />} perPage={DefaultListPerPage} pagination={<DefaultPagination />}
            aside={<DefaultFilterSidebar><IdFilter /></DefaultFilterSidebar>}>
        <Datagrid rowClick="edit">
            <TextField source="id" />
            <TextField source="title" />
        </Datagrid>
    </List>
);

const formatSrc = (value) => {
    let result = value
    if (!value ||  typeof value === "string") {
        result = { title: value, src: value };
    } else if (value instanceof Array) {
        result = value.map(v => ({ title: v, src: v }))
    }
    return result
}


const CustomSimpleForm = props => {
    const t = useTranslate();

    return (<SimpleForm {...props} redirect="list">
        <TextInput source="title" fullWidth />
        <TextInput source="titleShort" fullWidth />
        <BooleanInput source="visibleStudy" fullWidth />
        <AccordionInput source="process" fullWidth fields={['title', 'desc']} />
        <AccordionInput source="result" fullWidth fields={['title']} />
        <CustomTextInput source="desc" label={t('resources.course.fields.desc', { _: 'Description' })} />
        <CustomTextInput source="content" label={t('resources.course.fields.content', { _: 'Content' })} />
        {/* <NumberInput source="position" min="0" /> */}
        <ImageInput source="image" accept="image/*" format={ formatSrc }>
            <ImageField source="src" title="title" />
        </ImageInput>
        <TextInput source="tags" fullWidth helperText="Используйте запятую. Пример: wealth, investment, finance" />
        <ReferenceArrayInput source="teacherIds" reference="teacher" sort={{ field: 'title', order: 'ASC' }} perPage={1000} fullWidth>
            <AutocompleteArrayInput optionText="title" optionValue="id" />
        </ReferenceArrayInput>
        {/* <ReferenceInput source="teacherId" reference="teacher">
            <SelectInput optionText="title" />
        </ReferenceInput> */}
        <ReferenceArrayInput source="managerIds" reference="manager" sort={{ field: 'name', order: 'ASC' }} perPage={1000} fullWidth>
            <AutocompleteArrayInput optionText="name" optionValue="id" />
        </ReferenceArrayInput>
        <ReferenceArrayInput source="themes" reference="course_theme" sort={{ field: 'title', order: 'ASC' }} perPage={1000} fullWidth>
            <AutocompleteArrayInput optionText="title" optionValue="id" />
        </ReferenceArrayInput>
        <ReferenceArrayInput source="additionToСourses" reference="course" sort={{ field: 'title', order: 'ASC' }} perPage={1000} fullWidth>
            <AutocompleteArrayInput optionText="title" optionValue="id" />
        </ReferenceArrayInput>
        <ReferenceArrayInput source="needCourses" reference="course" sort={{ field: 'title', order: 'ASC' }} perPage={1000} fullWidth>
            <AutocompleteArrayInput optionText="title" optionValue="id" />
        </ReferenceArrayInput>
        {/* <RadioButtonGroupInput source="certificateType" label={labels.certificateType} choices={certificateTypes} /> */}
        <ReferenceInput source="certificateType" reference="certificate"  allowEmpty="true" fullWidth>
            <SelectInput optionText="title"/>
        </ReferenceInput>
        <ReferenceArrayInput source="gradeIds" reference="grade" sort={{ field: 'name', order: 'ASC' }} perPage={1000} fullWidth >
            <AutocompleteArrayInput optionText="name" optionValue="id" />
        </ReferenceArrayInput>
        <ReferenceArrayInput source="needGradeIds" reference="grade" sort={{ field: 'name', order: 'ASC' }} perPage={1000} fullWidth >
            <AutocompleteArrayInput optionText="name" optionValue="id" />
        </ReferenceArrayInput>
        <NumberInput source="gradeDuration" fullWidth />
        <LessonTree source="themes" />
    </SimpleForm>)
};

export const CourseEdit = props => (
    <Edit actions={<DefaultEditCreateActions />} {...props} mutationMode={'pessimistic'}>
        {<CustomSimpleForm />}
    </Edit>
);
export const CourseCreate = props => (
    <Create actions={<DefaultEditCreateActions />} {...props}>
        {<CustomSimpleForm />}
    </Create>
);
