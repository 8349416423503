import * as React from 'react';
import { AppBar, UserMenu } from 'react-admin';
import { makeStyles } from '@material-ui/core/styles';
import LocaleSwitcher from './localeSwitcher'
import { ReactComponent as LogoAcademy } from './logo-academy.svg';
import { ReactComponent as LogoEvorich } from './logo-evorich.svg';
import { ReactComponent as LogoWodcom } from './logo-wodcom.svg';
import { ReactComponent as LogoCoinset } from './logo-coinset.svg';
// import SettingsIcon from '@material-ui/icons/Settings';

const template = process.env.REACT_APP_TEMPLATE || 'academy'

let Logo

switch (template) {
    case 'evorich':
        Logo = LogoEvorich
        break;
    case 'wodcom':
        Logo = LogoWodcom
        break;
    case 'coinset':
        Logo = LogoCoinset
        break;
    default:
        Logo = LogoAcademy
        break;
}

const useStyles = makeStyles({
    title: {
        flex: 1,
        textOverflow: 'ellipsis',
        whiteSpace: 'nowrap',
        overflow: 'hidden',
    },
    logo: {
        height: 50,
    },
    spacer: {
        flex: 1,
    },
});
// const ConfigurationMenu = React.forwardRef(({ onClick }, ref) => (
//     <MenuItemLink
//         ref={ref}
//         to="/configuration"
//         primaryText="Configuration"
//         leftIcon={<SettingsIcon />}
//         onClick={onClick} // close the menu on click
//     />
// ));
const MyUserMenu = props => (
    <UserMenu {...props}>
        {/* <ConfigurationMenu /> */}
    </UserMenu>
);

const CustomAppBar = props => {
    const classes = useStyles();
    return (
        <AppBar {...props} userMenu={<MyUserMenu />}>
            {/* <Typography
                variant="h6"
                color="inherit"
                className={classes.title}
                id="react-admin-title"
            /> */}
            <Logo className={classes.logo} style={{ "padding-top": 6, "padding-bottom": 6}} />
            <span className={classes.spacer} />
            <LocaleSwitcher />
        </AppBar>
    );
};

export default CustomAppBar;