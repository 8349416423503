import * as React from 'react';
import {
  List,
  Datagrid,
  TextField,
  Edit,
  Create,
  SimpleForm,
  TextInput,
} from 'react-admin';
import {
  DefaultEditCreateActions,
  DefaultListActions,
  DefaultPagination,
} from '../lib/defaultConfig';
import RoleActionGroup from './components/RoleActionGroup';

const labels = {
  name: 'Имя роли',
  key: 'Ключ роли',
  actions: 'Действия',
};

export const ManagerRoleList = (props) => (
  <List
    {...props}
    actions={<DefaultListActions />}
    pagination={<DefaultPagination />}
  >
    <Datagrid rowClick="edit">
      <TextField source="id" />
      <TextField source="name" label={labels.name} />
    </Datagrid>
  </List>
);
export const ManagerRoleEdit = (props) => (
  <Edit {...props} actions={<DefaultEditCreateActions />} mutationMode={'pessimistic'}>
    <SimpleForm>
      <TextInput source="name" fullWidth label={labels.name} />
      <TextInput source="key" fullWidth label={labels.key} />
      <RoleActionGroup source="data" fullWidth label={labels.actions} />
    </SimpleForm>
  </Edit>
);
export const ManagerRoleCreate = (props) => (
  <Create {...props} actions={<DefaultEditCreateActions />}>
    <SimpleForm>
      <TextInput source="name" fullWidth label={labels.name} />
      <TextInput source="key" fullWidth label={labels.key} />
    </SimpleForm>
  </Create>
);
