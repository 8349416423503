import React from 'react';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Paper from '@mui/material/Paper';

function createData(name, calories, fat, carbs, protein) {
	return { name, calories, fat, carbs, protein };
}

const rows = [
	createData('Frozen yoghurt', 159, 6.0, 24, 4.0),
	createData('Ice cream sandwich', 237, 9.0, 37, 4.3),
	createData('Eclair', 262, 16.0, 24, 6.0),
	createData('Cupcake', 305, 3.7, 67, 4.3),
	createData('Gingerbread', 356, 16.0, 49, 3.9),
];

const BasicTable = ({ data, ...props }) => {

	if (data)
		return (
			<TableContainer component={Paper}>
				<Table sx={{ minWidth: 650 }} aria-label="simple table">
					<TableHead>
						<TableRow>
							{
								Object.entries(data[0]).map(([key], index) => (
									<React.Fragment key={index}>
										{
											index > 0 ?
												<TableCell key={index} align='right'>{key}</TableCell>
												:
												<TableCell key={index}>{key}</TableCell>
										}
									</React.Fragment>

								))
							}
						</TableRow>
					</TableHead>
					<TableBody>
						{data.map((row, index) => (
							<TableRow
								key={index}
								sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
							>
								{
									Object.entries(row).map(([key, value], index2) => (
										<React.Fragment key={index2}>
											{
												index2 > 0 ?
													<TableCell
														align='right'
													>
														{String(value)}
													</TableCell>
													:
													<TableCell
														component='th'
														scope='row'
													>
														{String(value)}
													</TableCell>
											}
										</React.Fragment>

									))
								}
							</TableRow>
						))}
					</TableBody>
				</Table>
			</TableContainer >
		)
	else return <></>
}

export default BasicTable