import * as React from "react";
import {
    List,
    Datagrid,
    TextField,
    Edit,
    Create,
    SimpleForm,
    TextInput,
    PasswordInput,
    BooleanInput,
    ReferenceInput,
    SelectInput,
    ReferenceArrayInput,
    AutocompleteArrayInput,
} from 'react-admin';
import { DefaultEditCreateActions, DefaultListActions, DefaultPagination, DefaultListPerPage } from '../lib/defaultConfig';
import { DefaultFilterSidebar, IdFilter, LoginFilter } from "../components/ListFilters";

export const ManagerList = props => (
    <List {...props} actions={<DefaultListActions />} pagination={<DefaultPagination />}
        perPage={DefaultListPerPage}
        aside={<DefaultFilterSidebar>
            <IdFilter />
            <LoginFilter />
        </DefaultFilterSidebar>}
    >
        <Datagrid rowClick="edit">
            <TextField source="id" />
            <TextField source="login" />
            <TextField source="name" />
        </Datagrid>
    </List>
);
// TODO reference="manager_role" optionValue не может принимать стринги при GET запросе. ошибка в беке. Ему нужен именно id(int)
const simpleForm =
    <SimpleForm>
        <TextInput source="login" fullWidth />
        <TextInput source="name" fullWidth />
        <PasswordInput source="password" fullWidth />
        <ReferenceArrayInput reference="manager_role" source="role">
            <AutocompleteArrayInput optionText="name" optionValue="key" />
        </ReferenceArrayInput>
        <BooleanInput source="active" />
        <ReferenceInput source="teacherId" reference="teacher" allowEmpty>
            <SelectInput optionText="title" optionValue="id" />
        </ReferenceInput>
        <ReferenceArrayInput source="langs" reference="langs" sort={{ field: 'title', order: 'ASC' }} perPage={1000} fullWidth>
            <AutocompleteArrayInput optionText="title" optionValue="id" />
        </ReferenceArrayInput>
    </SimpleForm>
export const ManagerEdit = props => (
    <Edit {...props} actions={<DefaultEditCreateActions />} mutationMode={'pessimistic'}>
        {simpleForm}
    </Edit>
);
export const ManagerCreate = props => (
    <Create {...props} actions={<DefaultEditCreateActions />}>
        {simpleForm}
    </Create>
);
