import * as React from "react"
import CustomTextInput from "./components/CustomTextInput"
import {
    List,
    Datagrid,
    TextField,
    Edit,
    Create,
    SimpleForm,
    TextInput,
    useTranslate
} from "react-admin"
import {
    DefaultEditCreateActions,
    DefaultListActions,
    DefaultPagination,
    DefaultListPerPage
} from "../lib/defaultConfig"

export const SystemSettingList = (props) => (
    <List {...props} actions={<DefaultListActions />} perPage={DefaultListPerPage} pagination={<DefaultPagination />}>
        <Datagrid rowClick="edit">
            <TextField source="key" />
            <TextField source="title" />
        </Datagrid>
    </List>
)

const CustomSimpleForm = props => {
    const t = useTranslate();

    return (<SimpleForm {...props}>
            <TextInput source="title" fullWidth />
            <TextInput source="key" fullWidth />
            <CustomTextInput source="desc" label={t('resources.system_setting.fields.desc', { _: 'Description' })} />
            <TextInput source="data" fullWidth multiline />
        </SimpleForm>)
};

export const SystemSettingEdit = (props) => (
    <Edit {...props} actions={<DefaultEditCreateActions />} mutationMode={'pessimistic'}>
        {<CustomSimpleForm />}
    </Edit>
)
export const SystemSettingCreate = (props) => (
    <Create {...props} actions={<DefaultEditCreateActions />}>
        {<CustomSimpleForm />}
    </Create>
)
