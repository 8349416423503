import React from 'react';
import {
	Chart as ChartJS,
	ArcElement,
	Tooltip,
	Legend,
	LinearScale,
	CategoryScale
} from 'chart.js';
import { Pie } from 'react-chartjs-2';
import { getGraphColors } from '../../lib/colors';
import classes from './Chart.module.css'

const PieChart = ({ template, ...props }) => {
	const graphColors = getGraphColors(template)

	ChartJS.register(ArcElement, Tooltip, Legend, LinearScale, CategoryScale);
	const options = {
		onHover: (event, chartElement) => {
			const target = event.native ? event.native.target : event.target;
			target.style.cursor = chartElement[0] ? 'pointer' : 'default';
		},
		responsive: true,
		maintainAspectRatio: false,
		plugins: {
			legend: {
				position: 'none',
			},
			title: {
				display: false,
			},
			tooltip: {
				displayColors: false
			}
		},
	};
	const data = {
		labels: props.labels || [],
		datasets: [
			{
				data: props.data || [],
				backgroundColor: [
					graphColors.opt1,
					graphColors.opt2,
					graphColors.secondary,
					graphColors.primary,
				],
				hoverBackgroundColor: [
					graphColors.opt1Hover,
					graphColors.opt2Hover,
					graphColors.secondaryHover,
					graphColors.primaryHover,
				],
				borderAlign: 'init',
				borderWidth: '3',
			},
		],
	};
	return <Pie className={classes.canvas} options={options} data={data} />;
}

export default PieChart