import * as React from "react";
import { makeStyles } from '@material-ui/core/styles';
import { FormControl, FormGroup, InputLabel, Box, MenuItem, Select, TextField } from '@material-ui/core';
import { Create, SimpleForm } from 'react-admin';
import Title from './components/UI/Title/Title'
import Subtitle from './components/UI/Subtitle/Subtitle'
import LessonText from './components/LessonText'
import LessonTest from './components/LessonTest'
import LessonAddition from "./components/LessonAddition";
// eslint-disable-next-line no-unused-vars
import { spacing } from '@material-ui/system';

const useStyles = makeStyles({
	flex__inputs: {
		justifyContent: 'space-between',
		paddingTop: '20px',
		width: '100%',
		flexWrap: 'nowrap',
	},
});

export const CourseExampleCreate = props => {
	const classes = useStyles();
	const [type, setType] = React.useState(0)
	const [types] = React.useState([
		{ id: 1, name: 'Программа' },
		{ id: 2, name: 'Курс' },
		{ id: 3, name: 'Модуль' },
		{ id: 4, name: 'Урок' },
		{ id: 5, name: 'Факультатив' }
	]);
	const [additions] = React.useState([
		{ id: 1, name: 'Тест', type: 'test' },
		{ id: 2, name: 'Текст', type: 'text' },
	]);
	const [components, setComponents] = React.useState([
		{
			id: '1',
			type: 'test',
			title: 'Тест 1',
			questions: [
				{
					id: 'sdfsfw',
					title: 'Новый вопрос',
					options: [
						{
							id: '111111111',
							correct: false,
							value: 0,
							title: 'Вариант 1 теста 1'
						}
					]
				}
			]
		},
		{
			id: '2',
			type: 'test',
			title: 'Тест 2',
			questions: [
				{
					id: 'etgerwg',
					title: 'Новый вопрос',
					options: [
						{
							id: '2222222',
							correct: true,
							value: 0,
							title: 'Вариант 1 теста 2'
						},
						{
							id: '333333333',
							correct: false,
							value: 9,
							title: 'Вариант 2 теста 2'
						}
					]
				}
			]
		}
	])
	const switchType = (event) => {
		setType(event.target.value)
	}
	const switchAddition = (event) => {
		setComponents([...components, { type: event.target.value, data: {} }])
	}
	const changeData = (data) => {
		let findData = Object.values(components).find((obj, index) => obj.id === data.id)
		let findDataIndex = components.indexOf(findData)
		let newComponents = JSON.parse(JSON.stringify(components))
		newComponents[findDataIndex] = data
		console.log(data)
		setComponents([...newComponents])
	}

	return (
		<Create {...props}>
			<SimpleForm>

				<Subtitle>Материалы /  Курс для ведущих игрового тренинга “Гений Финансов” (RU)</Subtitle>
				<Title>Курс для ведущих игрового тренинга “Гений Финансов”</Title>

				<FormGroup row className={classes.flex__inputs}>
					<TextField label="Название урока" variant="filled" fullWidth placeholder="Введите название урока" />
					<Box px={2} />
					<FormControl variant="filled" className={classes.formControl} fullWidth>
						<InputLabel id="lessonTypeLabel">Тип урока</InputLabel>
						<Select
							labelId="lessonTypeLabel"
							id="lessonType"
							value={type}
							onChange={switchType}
						>
							<MenuItem value="" disabled>
								<span>Нужно выбрать тип урока</span>
							</MenuItem>
							{types.map((type) => <MenuItem value={type.id}>{type.name}</MenuItem>)}
						</Select>
					</FormControl>
				</FormGroup>


				<FormGroup className={classes.flex__inputs}>
					<TextField
						id="lessonDescription"
						label="Описание"
						multiline
						rows={4}
						variant="filled"
						placeholder="Введите описание урока"
					/>
				</FormGroup>

				<LessonAddition switchAddition={switchAddition} additions={additions} />
				{components.map((component) => {
					if (component.type === "text") return <LessonText data={component} switchAddition={switchAddition} additions={additions} />
					else if (component.type === "test") return <LessonTest data={component} switchAddition={switchAddition} additions={additions} changeData={changeData} />
					else return <div style={{ display: "none" }}></div>
				})}
			</SimpleForm>
		</Create>
	)
};
