import React, { useState, useEffect } from "react";
import { useHistory } from "react-router-dom";
import {
	Table, TableBody, TableCell, TableContainer, TableHead, TableRow,
	// TablePagination
} from '@material-ui/core'
import Cookies from 'universal-cookie'
import axios from 'axios'
import { BarChart } from "../components/Charts";
import { Card, CardBody, CardHeader } from "../components/Card";
import { Row, Col } from "reactstrap";
const cookies = new Cookies();
const serverUrl = process.env.REACT_APP_APIURL || ''
const template = process.env.REACT_APP_TEMPLATE || 'academy'

const labels = {
	userId: "ID пользователя",
	userName: "Фамилия Имя пользователя",
	join: "Открыл урок",
	complete: "Выполнил задание к уроку",
	estimate: 'Получил баллов за задание к уроку',
}

export const LessonStatisticsList = ({ props }) => {
	const [token] = useState(cookies.get('token'))
	const [statistics, setStatistics] = useState([])
	const [chartLabels, setChartLabels] = useState([])
	const [chartData, setChartData] = useState({
		yes: [0, 0, 0],
		no: [0, 0, 0]
	})
	const history = useHistory()
	const pathArr = history.location.pathname.split('/')
	const courseId = pathArr[pathArr.length - 3]
	const moduleId = pathArr[pathArr.length - 1]

	useEffect(() => {
		getData()
	}, [])
	const getData = () => {
		axios.get(`${serverUrl}/report_statistic/course/${courseId}/module/${moduleId}`, {
			headers: { "A-Auth-Token": token },
		}).then((res) => {
			setStatistics(res.data)
			const chartDataCopy = { ...chartData }
			res.data.forEach(({ join, complete, estimate }) => {
				join === "Да" ? chartDataCopy.yes[0] += 1 : chartDataCopy.no[0] += 1
				complete === "Да" ? chartDataCopy.yes[1] += 1 : chartDataCopy.no[1] += 1
				estimate === "Да" ? chartDataCopy.yes[2] += 1 : chartDataCopy.no[2] += 1
			})
			setChartData(chartDataCopy)
			setChartLabels(['Открыл урок', 'Выполнил задание к уроку', 'Получил баллов за задание к уроку'])
		})
	}
	return (
		<>
			<Row className="w-100">
				<Col lg={12} md={12} className="py-2">
					<Card>
						<CardHeader>
							Статистика по уроку
						</CardHeader>
						<CardBody>
							<BarChart labels={chartLabels} data={chartData} template={template} />
						</CardBody>
					</Card>
				</Col>
			</Row>
			<TableContainer sx={{ maxHeight: 440 }}>
				<Table stickyHeader aria-label="sticky table">
					<TableHead>
						<TableRow>
							<TableCell>{labels.userId}</TableCell>
							<TableCell align="right">{labels.userName}</TableCell>
							<TableCell align="right">{labels.join}</TableCell>
							<TableCell align="right">{labels.complete}</TableCell>
							<TableCell align="right">{labels.estimate}</TableCell>
						</TableRow>
					</TableHead>
					<TableBody>
						{
							statistics.map((statistic) => (
								<TableRow hover key={statistic.userId}>
									<TableCell component="th" scope="row">{statistic.userId}</TableCell>
									<TableCell align="right">{statistic.userName}</TableCell>
									<TableCell align="right">{statistic.join}</TableCell>
									<TableCell align="right">{statistic.complete}</TableCell>
									<TableCell align="right">{statistic.estimate}</TableCell>
								</TableRow>
							))
						}
					</TableBody>
				</Table>
				{/* <TablePagination
					rowsPerPageOptions={[50, 100, 250]}
					component="div"
					count={statistics.length}
					rowsPerPage={50}
					page={0}
				onPageChange={handleChangePage}
				onRowsPerPageChange={handleChangeRowsPerPage}
				/> */}
			</TableContainer>
		</>
	)
};