import * as React from "react";
import RichTextInput from 'ra-input-rich-text';
import {
    List,
    Datagrid,
    TextField,
    Edit,
    Create,
    SimpleForm,
    TextInput,
    NumberInput,
    ReferenceArrayInput,
    AutocompleteArrayInput,
    ReferenceInput,
    SelectInput
} from 'react-admin';
import { DefaultEditCreateActions, DefaultListActions, DefaultPagination, DefaultListPerPage } from '../lib/defaultConfig';
import { DefaultFilterSidebar, IdFilter } from "../components/ListFilters";

export const CourseThemeList = props => (
    <List {...props} actions={<DefaultListActions />} perPage={DefaultListPerPage} pagination={<DefaultPagination />}
            aside={<DefaultFilterSidebar><IdFilter /></DefaultFilterSidebar>}>
        <Datagrid rowClick="edit">
            <TextField source="id" />            
            <TextField source="title" />
        </Datagrid>
    </List>
);
const simpleForm = 
<SimpleForm>
    <TextInput source="title" fullWidth />
    <RichTextInput source="desc" />
    <NumberInput source="position" min="0" />
    <ReferenceInput source="courseId" reference="course">
        <SelectInput optionText="title" />
    </ReferenceInput>
    <ReferenceArrayInput reference="course_theme_module" source="modules" sort={{ field: 'title', order: 'ASC' }} perPage={1000} fullWidth>
        <AutocompleteArrayInput optionText="title" optionValue="id"/>
    </ReferenceArrayInput>
    <NumberInput source="duration" />
</SimpleForm>
export const CourseThemeEdit = props => (
    <Edit {...props} actions={<DefaultEditCreateActions />} mutationMode={'pessimistic'}>
        {simpleForm}
    </Edit>
);
export const CourseThemeCreate = props => (
    <Create {...props} actions={<DefaultEditCreateActions />}>
        {simpleForm}
    </Create>
);
