import * as React from "react";
import {
    List,
    Datagrid,
    TextField,
    ReferenceField,
} from 'react-admin';
import { DefaultListActions, DefaultPagination } from '../lib/defaultConfig'

export const ReportCatalogUserList = props => (
    <List {...props} actions={<DefaultListActions />} pagination={<DefaultPagination />}>
        <Datagrid>
            <TextField source="id" />
            <ReferenceField source="userId" reference="user" link="show">
                <TextField source="id" />
            </ReferenceField>
            <TextField source="globalUserId" />
            <TextField source="userFullName" />
            <ReferenceField source="catalogId" reference="catalog">
                <TextField source="id" />
            </ReferenceField>
            <TextField source="catalogTitle" />

        </Datagrid>
    </List>
);