import * as React from 'react';
import List from '@material-ui/icons/List';

import { FilterList, FilterListItem } from 'react-admin';

const labels = {
    managerName: "Менеджер",
    route: "URL",
    type: 'Тип действия',
    actionCreate: 'Создание',
    actionUpdate: 'Обновление',
    actionDelete: 'Удаление',
    data: 'Данные',
    createdAt: 'Дата',
}
export const ManagerLogTypeFilter = () => (
    <FilterList label={labels.type} icon={<List />}>
        <FilterListItem
            label={labels.actionCreate}
            value={{
                type: 'create',
            }}
        />
        <FilterListItem
            label={labels.actionUpdate}
            value={{
                type: 'update',
            }}
        />
        <FilterListItem
            label={labels.actionDelete}
            value={{
                type: 'delete',
            }}
        />
    </FilterList>
);
export const ManagerLogRouteFilter = () => (
    <FilterList label={labels.type} icon={<List />}>
        <FilterListItem
            label={labels.actionCreate}
            value={{
                type: 'create',
            }}
        />
        <FilterListItem
            label={labels.actionUpdate}
            value={{
                type: 'update',
            }}
        />
        <FilterListItem
            label={labels.actionDelete}
            value={{
                type: 'delete',
            }}
        />
    </FilterList>
);