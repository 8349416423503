import * as React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import FormControl from '@material-ui/core/FormControl';
import Select from '@material-ui/core/Select';
import InputLabel from '@material-ui/core/InputLabel';
import MenuItem from '@material-ui/core/MenuItem';
import { useSetLocale } from 'react-admin';
import { langs, setLang } from './translate';
const LocaleSwitcher = () => {

  const locale = localStorage.getItem('locale')
  const setLocale = useSetLocale();

  const useStyles = makeStyles((theme) => ({
    formControl: {
    },
    baseSmall: {
      color: 'grey',
      fontSize: '10px'
    },
    baseText: {
      color: 'white',
      fontSize: '12px',
      marginTop: '5px!important'
    },
    selectEmpty: {
      marginTop: theme.spacing(2),
    },
  }));
  const classes = useStyles();
  const changeLang = (event) => {
    setLocale(event.target.value)
    localStorage.setItem('locale', event.target.value)
    setLang(event.target.value)
    window.location.reload()
  };
  return (
    <div>
      <FormControl className={classes.formControl}>
        <InputLabel className={classes.baseSmall} id="demo-simple-select-label">language</InputLabel>
        <Select
          className={classes.baseText}
          labelId="language-select-label"
          id="language-select"
          value={locale}
          onChange={changeLang}
        >
          {langs.map((lang, i) => {
            return (<MenuItem key={i} value={lang.key}>{lang.title}</MenuItem>)
          })}

        </Select>
      </FormControl>
    </div>
  );
};

export default LocaleSwitcher;