import * as React from "react";
import {
    List,
    Edit,
    Create,
    Datagrid,
    TextField,
    SimpleForm,
    DateField,
    DateTimeInput,
    TextInput
} from 'react-admin';
import { DefaultShowActions, DefaultListActions, DefaultEditCreateActions, DefaultPagination, DefaultListPerPage } from '../lib/defaultConfig';
import { DefaultFilterSidebar, UserIdFilter } from "../components/ListFilters";

export const UserMembershipList = props => (
    <List {...props} actions={<DefaultListActions />} pagination={<DefaultPagination />} 
        perPage={DefaultListPerPage}
        aside={<DefaultFilterSidebar>
            <UserIdFilter global />
        </DefaultFilterSidebar>}
    >
        <Datagrid rowClick="show">
            <TextField source="id" />
            <TextField source="userId" fullWidth />
            <DateField source="start" showTime locales="ru-RU" />
            <DateField source="end" showTime locales="ru-RU" />
        </Datagrid>
    </List>
);

const simpleForm =
    <SimpleForm>
        <TextInput source="userId" fullWidth />
        <DateTimeInput source="start" locales="ru-RU" />
        <DateTimeInput source="end" locales="ru-RU" />
    </SimpleForm>

export const UserMembershipEdit = props => (
    <Edit {...props} actions={<DefaultEditCreateActions />} mutationMode={'pessimistic'}>
        {simpleForm}
    </Edit>
);
export const UserMembershipCreate = props => (
    <Create {...props} actions={<DefaultEditCreateActions />}>
        {simpleForm}
    </Create>
);