import * as React from "react";
import {
    List,
    Datagrid,
    TextField,
    Edit,
    Create,
    SimpleForm,
    ReferenceInput,
    ReferenceField,
    SelectInput
} from 'react-admin';
import { DefaultEditCreateActions, DefaultListActions, DefaultPagination, DefaultListPerPage } from '../lib/defaultConfig'

const validate = (values) => {
    const errors = {};
    if (values.guidanceCourseId === values.courseId) {
        errors.guidanceCourseId = 'Нельзя рекомендовать на такой же курс';
    }
    return errors
};
export const CourseGuidancesList = props => (
    <List {...props} actions={<DefaultListActions />} perPage={DefaultListPerPage} pagination={<DefaultPagination />}>
        <Datagrid rowClick="edit">
            <TextField source="id" />
            <ReferenceField link={false} source="courseId" reference="course">
                <TextField source="title" />
            </ReferenceField>
            <ReferenceField link={false} source="guidanceCourseId" reference="course">
                <TextField source="title" />
            </ReferenceField>
        </Datagrid>
    </List >
);
const simpleForm =
    <SimpleForm validate={validate}>
        <ReferenceInput source="courseId" reference="course" fullWidth>
            <SelectInput optionText="title" />
        </ReferenceInput>
        <ReferenceInput source="guidanceCourseId" reference="course" fullWidth>
            <SelectInput optionText="title" />
        </ReferenceInput>
    </SimpleForm>
export const CourseGuidancesEdit = props => (
    <Edit {...props} actions={<DefaultEditCreateActions />} mutationMode={'pessimistic'}>
        {simpleForm}
    </Edit>
);
export const CourseGuidancesCreate = props => (
    <Create {...props} actions={<DefaultEditCreateActions />}>
        {simpleForm}
    </Create>
);
