import * as React from "react";
import CustomTextInput from './components/CustomTextInput';
import {
    List,
    Datagrid,
    TextField,
    Edit,
    Create,
    SimpleForm,
    TextInput,
    ImageInput,
    ImageField,
    BooleanInput,
    RadioButtonGroupInput,
    useTranslate
} from 'react-admin';
import { DefaultEditCreateActions, DefaultListActions, DefaultPagination, DefaultListPerPage } from '../lib/defaultConfig';
import { DefaultFilterSidebar, IdFilter } from "../components/ListFilters";

const types = [
    { id: 1, name: 'Разное' },
    { id: 2, name: 'Недвижимость' },
    { id: 3, name: 'Природные ресурсы' },
    { id: 4, name: 'Технологии' }
]

export const PartnerProjectList = props => (
    <List {...props} actions={<DefaultListActions />} pagination={<DefaultPagination />}
        perPage={DefaultListPerPage}
        aside={<DefaultFilterSidebar>
            <IdFilter />
        </DefaultFilterSidebar>}
    >
        <Datagrid rowClick="edit">
            <TextField source="id" />
            <TextField source="title" />
        </Datagrid>
    </List>
);

const CustomSimpleForm = props => {
    const t = useTranslate();

    return (<SimpleForm {...props}>
        <TextInput source="title" fullWidth />
        <BooleanInput source="visible" />
        <CustomTextInput source="desc" label={t('resources.partner_project.fields.desc', { _: 'Description' })} />
        <CustomTextInput source="content" label={t('resources.partner_project.fields.content', { _: 'Content' })} />
        <RadioButtonGroupInput source="type" choices={types} />
        <ImageInput source="image" accept="image/*">
            <ImageField source="src" title="title" />
        </ImageInput>
        <TextInput source="video" fullWidth />
        <TextInput source="url" fullWidth />
    </SimpleForm>)
};

export const PartnerProjectEdit = props => (
    <Edit {...props} actions={<DefaultEditCreateActions/>} mutationMode={'pessimistic'}>
        {<CustomSimpleForm />}
    </Edit>
);
export const PartnerProjectCreate = props => (
    <Create {...props} actions={<DefaultEditCreateActions/>}>
        {<CustomSimpleForm />}
    </Create>
);
