export const getGraphColors = (template) => {
	switch (template) {
		case 'academy':
			return {
				colorArray: ['rgb(45,15,25, 0.9)', 'rgb(113,51,101, 0.9)', 'rgb(122,64,55, 0.9)', 'rgb(118,64,83, 0.9)'],
				hoverArray: ['rgb(45,15,25)', 'rgb(113,51,101)', 'rgb(122,64,55)', 'rgb(118,64,83)'],
				transparentArray: ['rgb(45,15,25, 0.5)', 'rgb(113,51,101, 0.5)', 'rgb(122,64,55, 0.5)', 'rgb(118,64,83, 0.5)'],
				primary: 'rgb(45,15,25, 0.9)',
				secondary: 'rgb(113,51,101, 0.9)',
				opt1: 'rgb(122,64,55, 0.9)',
				opt2: 'rgb(118,64,83, 0.9)',
				primaryHover: 'rgb(45,15,25)',
				secondaryHover: 'rgb(113,51,101)',
				opt1Hover: 'rgb(122,64,55)',
				opt2Hover: 'rgb(118,64,83)',
				primaryTransparent: 'rgb(45,15,25, 0.5)',
				secondaryTransparent: 'rgb(113,51,101, 0.5)',
				opt1Transparent: 'rgb(122,64,55, 0.5)',
				opt2Transparent: 'rgb(118,64,83, 0.5)',
			}
		case 'evorich':
			return {
				colorArray: ['rgb(97,140,239, 0.9)', 'rgb(115,103,240, 0.9)', 'rgb(148,96,239, 0.9)', 'rgb(58,62,203, 0.9)'],
				hoverArray: ['rgb(97,140,239)', 'rgb(115,103,240)', 'rgb(148,96,239)', 'rgb(58,62,203)'],
				transparentArray: ['rgb(97,140,239, 0.5)', 'rgb(115,103,240, 0.5)', 'rgb(148,96,239, 0.5)', 'rgb(58,62,203, 0.5)'],
				primary: 'rgb(97,140,239, 0.9)',
				secondary: 'rgb(115,103,240, 0.9)',
				opt1: 'rgb(148,96,239, 0.9)',
				opt2: 'rgb(58,62,203, 0.9)',
				primaryHover: 'rgb(97,140,239)',
				secondaryHover: 'rgb(115,103,240)',
				opt1Hover: 'rgb(148,96,239)',
				opt2Hover: 'rgb(58,62,203)',
				primaryTransparent: 'rgb(97,140,239, 0.5)',
				secondaryTransparent: 'rgb(115,103,240, 0.5)',
				opt1Transparent: 'rgb(148,96,239, 0.5)',
				opt2Transparent: 'rgb(58,62,203, 0.5)',
			}
		case 'coinset':
			return {
				colorArray: ['rgb(97,140,239, 0.9)', 'rgb(115,103,240, 0.9)', 'rgb(148,96,239, 0.9)', 'rgb(58,62,203, 0.9)'],
				hoverArray: ['rgb(97,140,239)', 'rgb(115,103,240)', 'rgb(148,96,239)', 'rgb(58,62,203)'],
				transparentArray: ['rgb(97,140,239, 0.5)', 'rgb(115,103,240, 0.5)', 'rgb(148,96,239, 0.5)', 'rgb(58,62,203, 0.5)'],
				primary: 'rgb(97,140,239, 0.9)',
				secondary: 'rgb(115,103,240, 0.9)',
				opt1: 'rgb(148,96,239, 0.9)',
				opt2: 'rgb(58,62,203, 0.9)',
				primaryHover: 'rgb(97,140,239)',
				secondaryHover: 'rgb(115,103,240)',
				opt1Hover: 'rgb(148,96,239)',
				opt2Hover: 'rgb(58,62,203)',
				primaryTransparent: 'rgb(97,140,239, 0.5)',
				secondaryTransparent: 'rgb(115,103,240, 0.5)',
				opt1Transparent: 'rgb(148,96,239, 0.5)',
				opt2Transparent: 'rgb(58,62,203, 0.5)',
			}
		case 'wodcom':
			return {
				colorArray: ['rgb(45,15,25, 0.9)', 'rgb(113,51,101, 0.9)', 'rgb(122,64,55, 0.9)', 'rgb(118,64,83, 0.9)'],
				hoverArray: ['rgb(45,15,25)', 'rgb(113,51,101)', 'rgb(122,64,55)', 'rgb(118,64,83)'],
				transparentArray: ['rgb(45,15,25, 0.5)', 'rgb(113,51,101, 0.5)', 'rgb(122,64,55, 0.5)', 'rgb(118,64,83, 0.5)'],
				primary: 'rgb(45,15,25, 0.9)',
				secondary: 'rgb(113,51,101, 0.9)',
				opt1: 'rgb(122,64,55, 0.9)',
				opt2: 'rgb(118,64,83, 0.9)',
				primaryHover: 'rgb(45,15,25)',
				secondaryHover: 'rgb(113,51,101)',
				opt1Hover: 'rgb(122,64,55)',
				opt2Hover: 'rgb(118,64,83)',
				primaryTransparent: 'rgb(45,15,25, 0.5)',
				secondaryTransparent: 'rgb(113,51,101, 0.5)',
				opt1Transparent: 'rgb(122,64,55, 0.5)',
				opt2Transparent: 'rgb(118,64,83, 0.5)',
			}
		default:
			return {
				colorArray: ['rgb(45,15,25, 0.9)', 'rgb(113,51,101, 0.9)', 'rgb(122,64,55, 0.9)', 'rgb(118,64,83, 0.9)'],
				hoverArray: ['rgb(45,15,25)', 'rgb(113,51,101)', 'rgb(122,64,55)', 'rgb(118,64,83)'],
				transparentArray: ['rgb(45,15,25, 0.5)', 'rgb(113,51,101, 0.5)', 'rgb(122,64,55, 0.5)', 'rgb(118,64,83, 0.5)'],
				primary: 'rgb(97,140,239, 0.9)',
				secondary: 'rgb(115,103,240, 0.9)',
				opt1: 'rgb(148,96,239, 0.9)',
				opt2: 'rgb(58,62,203, 0.9)',
				primaryHover: 'rgb(97,140,239)',
				secondaryHover: 'rgb(115,103,240)',
				opt1Hover: 'rgb(148,96,239)',
				opt2Hover: 'rgb(58,62,203)',
				primaryTransparent: 'rgb(97,140,239, 0.5)',
				secondaryTransparent: 'rgb(115,103,240, 0.5)',
				opt1Transparent: 'rgb(148,96,239, 0.5)',
				opt2Transparent: 'rgb(58,62,203, 0.5)',
			}
	}
}

export const getTemplateColors = (template) => {
	switch (template) {
		case 'academy':
			return {
				buttonError: 'rgb(45,15,25, 0.9)',
				buttonSuccess: '#2d0f19',
				buttonWarning: '#DDDDDD',
				buttonTextError: '#FFFFFF',
buttonTextWarning: '#000000',
buttonTextSuccess: '#FFFFFF'
			}
		default:
			return {
				buttonError: 'rgb(45,15,25, 0.9)',
				buttonSuccess: '#2d0f19',
				buttonWarning: '#DDDDDD',
				buttonTextError: '#FFFFFF',
				buttonTextWarning: '#000000',
				buttonTextSuccess: '#FFFFFF'
			}
	}
}

