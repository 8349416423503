import * as React from "react";
import {
    List,
    Datagrid,
    TextField
} from 'react-admin';
import { DefaultListActions, DefaultPagination } from '../lib/defaultConfig'

export const ReportUserAchiList = props => (
    <List
        {...props}
        actions={<DefaultListActions
        />}
        pagination={<DefaultPagination />}
    >
        <Datagrid>
            <TextField source="id" />
            <TextField source="userId" />
            <TextField source="userName" />
            <TextField source="userEmail" />
            <TextField source="c3AccessDate" />
            <TextField source="c3CertDate" />
            <TextField source="c3CertNumber" />
            <TextField source="c3CertLang" />
            <TextField source="c2AccessDate" />
            <TextField source="c2CertDate" />
            <TextField source="c2CertNumber" />
            <TextField source="c2CertLang" />
            <TextField source="c1AccessDate" />
            <TextField source="c1CertDate" />
            <TextField source="c1CertNumber" />
            <TextField source="c1CertLang" />
            <TextField source="c0AccessDate" />
            <TextField source="c0CertDate" />
            <TextField source="c0CertNumber" />
            <TextField source="c0CertLang" />
        </Datagrid>
    </List>
);