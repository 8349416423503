import * as React from "react";

import {
    List,
    Datagrid,
    TextField,

} from 'react-admin';
import { DefaultListActions, DefaultPagination } from '../lib/defaultConfig'



export const ReportCertificateList = props => (
    <List {...props} actions={<DefaultListActions />} pagination={<DefaultPagination />}>
        <Datagrid >
            <TextField source="userId" />
            <TextField source="userFullName" />
            <TextField source="userLogin" />
            <TextField source="userEmail" />
            <TextField source="certName" />
            <TextField source="date" />
            <TextField source="lang" />
        </Datagrid>
    </List>
);
