import * as React from "react";
import {
    List,
    Datagrid,
    TextField,
    Edit,
    Create,
    SimpleForm,
    TextInput,
} from 'react-admin';
import CustomListActions from '../lib/CustomListActions'
export const CartList = props => (
    <List {...props} actions={<CustomListActions />}>
        <Datagrid rowClick="edit">
            <TextField source="id" />
            <TextField source="userId" />
            <TextField source="itemId" />
        </Datagrid>
    </List>
);
export const CartEdit = props => (
    <Edit {...props} mutationMode={'pessimistic'}>
        <SimpleForm>
            <TextInput source="userId" />
            <TextInput source="itemId" />
        </SimpleForm>
    </Edit>
);
export const CartCreate = props => (
    <Create {...props}>
        <SimpleForm>
            <TextInput source="userId" />
            <TextInput source="itemId" />
        </SimpleForm>
    </Create>
);