// in src/Foo.js
import React, { Component } from 'react'
import Card from '@material-ui/core/Card'
import axios from 'axios'
import {
  FormGroup,
  Button,
  TextField,
  Accordion,
  AccordionSummary,
  Typography,
  AccordionDetails,
  ButtonGroup,
  Link
} from '@material-ui/core'
import classes from '../Model/components/Components.module.css'
import ExpandMoreIcon from '@material-ui/icons/ExpandMore'
import HourglassEmptyIcon from '@material-ui/icons/HourglassEmpty'
import AttachFileIcon from '@material-ui/icons/AttachFile';
import styled from 'styled-components'
import { formatDate, getHours } from '../lib/lib'
import Cookies from 'universal-cookie'
import { withTranslate } from 'react-admin';

const cookies = new Cookies();

const serverUrl = process.env.REACT_APP_APIURL || ''
const fileUrl = process.env.REACT_APP_IMAGEURL || ''

const StyledLink = styled(Link)`
  font-weight: 600;
  color: #2D0F19;
  text-align: left;
`

const CommentWrapper = styled.div`
    padding-top: 20px;
`

const Head = styled.div`
    display: flex;
    justify-content: space-between;
    .from {
        font-weight: 600;
        color: #2D0F19;
    }
    .date {
        color: #7E7E7E;
    }
`

const Comment = styled.div`
    padding-top: 10px;
    color: #7E7E7E;
`

const Attachments = styled.div`
    display: flex;
    padding-top: 10px;
`

const File = styled.div`
    display: flex;
    align-items: center;
    padding-right: 10px;
    span {
        padding-left: 4px;
    }
    &:hover {
        cursor: pointer;
    }
`


class App extends Component {

  state = {
    selectedFile: null,
    page: 1,
    rows: [],
    rowComments: [],
    rowGrades: [],
    token: cookies.get('token')
  };

  onFileChange = event => {
    this.setState({ selectedFile: event.target.files[0] });
  };
  componentDidMount = () => {
    console.log(this.state.token)
    this.getData()
  }
  getData = () => {
    const locale = localStorage.getItem('locale')
    axios.get(`${serverUrl}/admin_panel/course_task_file`, {
      headers: { "A-Auth-Token": this.state.token, 'Accept-Language': locale },
    }).then((res) => {
      this.setState({ rows: res.data.rows })
    })
  }

  changeTextField = (event, id) => {
    let rowComments = this.state.rowComments
    rowComments[id] = event.target.value
    this.setState({ rowComments })
  }
  changeGrade = (event, id) => {
    let rowGrades = this.state.rowGrades
    rowGrades[id] = event.target.value
    this.setState({ rowGrades })
  }
  sendComment = (id, type) => {
    axios.post(`${serverUrl}/admin_panel/course_task_file`,
      {
        comment: this.state.rowComments[id],
        grade: this.state.rowGrades[id],
        taskFileId: id,
        status: type
      }, {
      headers: { "A-Auth-Token": this.state.token },
    }).then((res) => {
      this.getData()
    })
  }

  taskFileOpenRequest = (id) => {
    axios.post(`${serverUrl}/admin_panel/course_task_file_open`,
      {
        taskFileId: id,
      }, {
      headers: { "A-Auth-Token": this.state.token },
    })
  }

  render() {
    const { translate } = this.props;
    return (
      <Card>
        <h2>{translate('pages.course_file_task.title')}</h2>
        {this.state.rows.length ? this.state.rows.map((row, index) =>
          <Accordion onClick={() => this.taskFileOpenRequest(row.id)}>
            <AccordionSummary expandIcon={<ExpandMoreIcon />}>
              <HourglassEmptyIcon color="primary" />
              <Typography>[{row.lang}] {row.courseName} {row.title} </Typography>
            </AccordionSummary>
            <AccordionDetails className={classes.flex__column}>
              {
                row.userId ?
                  <StyledLink
                    component="button"
                    variant="body2"
                    onClick={() => {
                      window.open(`#/userProfile/${row.userId}`, "_blank")
                    }}
                  >
                    {row.userFullName} 
                    {/* {translate('pages.course_file_task.user_card')} */}
                  </StyledLink>
                  : ''
              }
              {
                row.comments ?
                  row.comments.map(({ id, from, createdAt, comment, files }) => (
                    <CommentWrapper>
                      <Head>
                        {
                          from === 'user' ?
                            <span className='md-font-size from'>{translate('pages.course_file_task.user')}</span> :
                            <span className='md-font-size from'>{translate('pages.course_file_task.teacher')}</span>
                        }
                        <span className='md-font-size date'>{`${formatDate(createdAt)} ${getHours(createdAt)}`}</span>
                      </Head>
                      <Comment className='md-font-size'>
                        {comment}
                      </Comment>
                      <Attachments>
                        {
                          files ?
                            files.map((file, index) => (
                              <File
                                key={`file-${index}`}
                                onClick={() => window.open(`${fileUrl}/${file}`, "_blank")}
                              >
                                <AttachFileIcon size={16} />
                                <span className='sm-font-size'>{translate('pages.course_file_task.task_file')}</span>
                              </File>
                            )) : ''
                        }
                      </Attachments>
                    </CommentWrapper >
                  )) : ''
              }
              <h4>{translate('pages.course_file_task.leave_a_comment')}</h4>
              <FormGroup row className={`${classes.flex__inputs} ${classes.align__center} `}>
                <TextField
                  placeholder={translate('pages.course_file_task.text')}
                  variant="filled"
                  fullWidth
                  className={classes.question_title__input}
                  onChange={(event) => this.changeTextField(event, row.id)}
                />
              </FormGroup>
              <FormGroup row className={`${classes.flex__inputs} ${classes.align__center} `}>
                <TextField
                  placeholder={translate('pages.course_file_task.evaluation')}
                  variant="filled"
                  fullWidth
                  className={classes.question_title__input}
                  onChange={(event) => this.changeGrade(event, row.id)}
                />
              </FormGroup>
              <ButtonGroup>
                <Button
                  variant="contained"
                  color={'primary'}
                  onClick={() => this.sendComment(row.id, -1)}
                >
                  {translate('pages.course_file_task.reject')}
                </Button>{' '}
                <Button
                  variant="contained"
                  color={'default'}
                  onClick={() => this.sendComment(row.id, 0)}
                >
                  {translate('pages.course_file_task.expectation')}
                </Button>
                <Button
                  variant="contained"
                  color={'primary'}
                  onClick={() => this.sendComment(row.id, 1)}
                >
                  {translate('pages.course_file_task.to_accept')}
                </Button>
              </ButtonGroup>
            </AccordionDetails>

          </Accordion>) : translate('pages.course_file_task.no_answers_to_tasks')}


      </Card>
    );
  }
}
export default withTranslate(App)