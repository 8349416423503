import * as React from "react";

import {
    List,
    Datagrid,
    TextField,
} from 'react-admin';
import { DefaultListActions, DefaultPagination } from '../lib/defaultConfig'

export const ReportCompleteList = props => (
    <List {...props} actions={<DefaultListActions />} pagination={<DefaultPagination />}>
        <Datagrid >
            <TextField source="id" />
            <TextField source="globalUserId" onClick={() => console.log(props)} />
            <TextField source="userFullName" />
            <TextField source="userLogin" />
            <TextField source="userEmail" />
            <TextField source="completeData" />
        </Datagrid>
    </List>
);
